import styled from "@emotion/styled";
import { useEffect } from "react";
import { useDispatch, Modal } from "common";
import { closeModal } from "actions";
import { push } from "connected-next-router";
import { theme } from "theme";
import routes from "routes";

export const LEGAL_STORAGE_KEY = "accepted-legal";

export const LegalModal = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    window.localStorage.setItem(LEGAL_STORAGE_KEY, new Date().toISOString());
  }, []);

  const navigateToLegal = () => {
    dispatch(push(routes.legal()));
    dispatch(closeModal());
  };

  return (
    <SModal
      title="Aviso legal"
      disableCloseButton
      cancelButton={{
        hide: true,
      }}
      submitButton={{
        text: "Continuar",
        onClick: () => dispatch(closeModal()),
      }}
    >
      <p>
        Al navegar en LOCALEROTICA, estás dando por aceptados los{" "}
        <span onClick={navigateToLegal}>Términos y Condiciones</span> que rigen
        sobre el contenido de este sitio. También estás permitiendo el uso de
        cookies para los fines descritos en la{" "}
        <span onClick={navigateToLegal}>Política de Privacidad.</span>
      </p>
    </SModal>
  );
};

const SModal = styled(Modal)`
  text-align: left;
  max-width: 400px;

  p {
    span {
      color: ${theme.palette.blue.main};
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;
