// Material Components
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";

import { useEffect } from "react";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import { useIsMobile, useSelector } from "common";
import { selectModals } from "selectors";
import { closeModal } from "actions";

export const ModalManager = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const Modals = useSelector(selectModals);
  const isMobile = useIsMobile();

  useEffect(() => {
    // If the user clicks "back" in their browser and
    // there's a modal open, close it and stop backNavigation
    router.beforePopState(() => {
      if (Modals.length) {
        dispatch(closeModal());
        return false;
      }
      return true;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Modals]);

  const close = () => {
    dispatch(closeModal());
  };

  return (
    <>
      {Modals.map(({ node: Node, fullscreen, disableEscClose }, idx) => (
        <Dialog
          // An element of Dialog has an aria-role of "none presentation",
          // which throws an error in Chrome's lighthouse.
          // This overrides the role to just "presentation"
          TransitionProps={{ role: "presentation" } as any}
          fullScreen={
            fullscreen === "always" || (fullscreen === "mobile" && isMobile)
          }
          key={idx}
          open
          onClose={(_, reason) => {
            if (reason === "backdropClick") return;
            if (disableEscClose && reason === "escapeKeyDown") return;
            close();
          }}
          PaperComponent={Paper}
        >
          {Node}
        </Dialog>
      ))}
    </>
  );
};
