// Material Components
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
// lodash
import includes from "lodash/includes";

import { MouseEventHandler } from "react";
import styled from "@emotion/styled";
import { useSelector, useDispatch } from "common";
import { selectFavouritePostIds } from "selectors";
import { updateFavouritePosts } from "actions";
import { TPost } from "types";
import { theme } from "theme";

export const Favourite = ({ post }: { post?: TPost }) => {
  const dispatch = useDispatch();
  const favouritePostIds = useSelector(selectFavouritePostIds);
  const isFavourite = includes(favouritePostIds, post?.id);

  const removeFavourite: MouseEventHandler = (event) => {
    event.preventDefault();
    dispatch(updateFavouritePosts({ action: "remove", postId: post!.id }));
  };

  const addFavourite: MouseEventHandler = (event) => {
    event.preventDefault();
    dispatch(updateFavouritePosts({ action: "add", postId: post!.id }));
  };

  return post ? (
    <FavouriteIconContainer
      onClick={isFavourite ? removeFavourite : addFavourite}
    >
      {isFavourite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
    </FavouriteIconContainer>
  ) : null;
};

const FavouriteIconContainer = styled.div`
  margin-left: auto;
  svg {
    color: ${theme.palette.primary.main};
    height: 33px;
    width: auto;

    &:hover {
      border-radius: 100%;
      background: ${theme.palette.primary.main};
      color: white;
    }
  }
`;
