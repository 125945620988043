// Material Components
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";

// Material Icons
import WarningIcon from "@mui/icons-material/Warning";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { FC, ReactNode } from "react";
import styled from "@emotion/styled";
import { Button, useDispatch } from "common";
import { closeModal } from "actions";
import { theme } from "theme";

type Variant = "warning" | "error" | "success";

export type ModalProps = {
  className?: string;
  title: string;
  loading?: boolean;
  submitButton?: {
    text: string;
    onClick: () => void;
    disabled?: boolean;
  };
  cancelButton?: {
    text?: string;
    hide?: boolean;
  };
  onCancel?: () => void;
  variant?: Variant;
  children?: ReactNode;
  disableCloseButton?: boolean;
};

export const Modal = ({
  className,
  title,
  loading,
  submitButton,
  variant,
  cancelButton,
  children,
  onCancel,
  disableCloseButton,
}: ModalProps) => {
  const dispatch = useDispatch();

  const close = () => {
    onCancel?.();
    dispatch(closeModal());
  };

  const Icon = variant ? iconMap[variant] : undefined;

  const submit = () => {
    submitButton?.onClick();
  };

  return (
    <>
      <TitleBar>
        <h1>{title}</h1>
        <IconButton
          size="small"
          onClick={close}
          aria-label="Cerrar"
          disabled={loading || disableCloseButton}
        >
          <CloseIcon />
        </IconButton>
      </TitleBar>
      <SDialogContent className={className}>
        <ContentContainer>
          {Icon && <Icon />}
          {children}
        </ContentContainer>
        <SDialogActions>
          {!cancelButton?.hide && (
            <Button onClick={close} disabled={loading} color="secondary">
              {cancelButton?.text ?? "Cancelar"}
            </Button>
          )}
          {submitButton && (
            <Button
              onClick={submit}
              disabled={submitButton.disabled}
              loading={loading}
            >
              <span>{submitButton.text}</span>
            </Button>
          )}
        </SDialogActions>
      </SDialogContent>
    </>
  );
};

const SDialogContent = styled(DialogContent)`
  border: 2px solid ${theme.palette.primary.dark};
  border-top: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${theme.palette.background.default};
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1;

  > * {
    max-width: 90%;
  }
`;
const ColoredIcon = styled.div<{ color: string }>`
  svg {
    height: 100px;
    width: 100px;
    color: ${({ color }) => color};
  }
`;
const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${theme.palette.primary.dark};
  padding: 10px;

  h1 {
    margin: 0;
    margin-left: 20px;
    font-size: 1.5rem;
    width: 100%;
    font-weight: bold;
  }

  button {
    margin-right: 10px;
    margin-left: 10px;

    svg {
      color: white;
    }
  }
`;
export const SDialogActions = styled(DialogActions)`
  height: 70px;
  margin-top: 20px;

  ${theme.breakpoints.down("sm")} {
    margin-bottom: 40px;
  }
`;

const iconMap: { [key in Variant]: FC } = {
  success: function Success() {
    return (
      <ColoredIcon color={theme.palette.success.main}>
        <CheckCircleIcon />
      </ColoredIcon>
    );
  },
  error: function Error() {
    return (
      <ColoredIcon color={theme.palette.error.main}>
        <WarningIcon />
      </ColoredIcon>
    );
  },
  warning: function Warning() {
    return (
      <ColoredIcon color="#FFCF00">
        <WarningIcon />
      </ColoredIcon>
    );
  },
};
