// lodash
import concat from "lodash/concat";
import shuffle from "lodash/shuffle";
import filter from "lodash/filter";
import join from "lodash/join";
import compact from "lodash/compact";
import map from "lodash/map";
import every from "lodash/every";
import some from "lodash/some";
import remove from "lodash/remove";

import { useState, useEffect } from "react";
import { getPostsRequest } from "requests";
import { useCountry, useDispatch, useSelector } from "common";
import {
  selectFilters,
  selectAllPostsById,
  selectPostsFetchedAt,
  selectAllMediaIdsByPostId,
  selectAllMediaById,
} from "selectors";
import { TPost, GetPostsQuery } from "types";

type Props = {
  query: Omit<GetPostsQuery, "country">;
};

// TODO(gualcm): update this once we get more posts
export const usePosts = ({
  query,
}: Props): {
  posts: Array<TPost>;
  isDoneFetching: boolean;
} => {
  const dispatch = useDispatch();
  const [queryPosts, setQueryPosts] = useState<Array<TPost>>([]);
  const posts = useSelector(selectAllPostsById);
  const media = useSelector(selectAllMediaById);
  const postsMedia = useSelector(selectAllMediaIdsByPostId);
  const country = useCountry().code;
  const filters = useSelector(selectFilters);

  // Last time we fetched the country, since we're fetching ALL
  // posts for a country
  const postsFetchedAt = useSelector((state) =>
    selectPostsFetchedAt(state, { country })
  );

  useEffect(() => {
    const thirtyMinsAgo = new Date();
    thirtyMinsAgo.setMinutes(thirtyMinsAgo.getMinutes() - 30);

    if (!postsFetchedAt || postsFetchedAt < thirtyMinsAgo) {
      dispatch(getPostsRequest({ country }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const filteredByCategory = filter(posts, { category: query.category });

    const filteredByEstado = query.estado
      ? filter(filteredByCategory, {
          estado: query.estado,
        })
      : filteredByCategory;

    const filteredByCiudad = query.localidad
      ? filter(filteredByEstado, { localidad: query.localidad })
      : filteredByEstado;

    // we filter inactive so if someone disables a post, the post
    // is filtered in their feed
    const _filteredInactive = filter(filteredByCiudad, { active: true });

    // remove mutates `filteredInactive`,
    // meaning all non-top posts are left there
    const _topPosts = remove(_filteredInactive, (post) => post.top);

    // TODO(gualcm): remove this when we get more posts
    const filteredInactive = concat(_filteredInactive, _filteredInactive);
    const topPosts = concat(_topPosts, _topPosts);

    const newQueryPosts = concat(shuffle(topPosts), shuffle(filteredInactive));

    setQueryPosts(newQueryPosts);
  }, [posts, query.category, query.estado, query.localidad]);

  const filteredPosts = filter(queryPosts, (post) => {
    let keep = true;
    const { onlyVerified, onlyNoPrepay, onlyWithVideo } = filters;

    const mediaIds = postsMedia[post.id];
    const postMedia = map(mediaIds, (mediaId) => media[mediaId]);

    if (onlyVerified) {
      const mediaStatusIsVerified = map(
        postMedia,
        (mediaItem) => mediaItem.status === "verified"
      );
      keep = keep && every(mediaStatusIsVerified);
    }

    if (onlyWithVideo) {
      keep = keep && some(map(postMedia, ({ type }) => type === "video"));
    }

    if (onlyNoPrepay) {
      keep = keep && post.noAdvancePayment;
    }

    return keep;
  });

  // const paginatedPosts = take(filteredPosts, page * PAGE_SIZE);

  return {
    posts: filteredPosts,
    isDoneFetching: Boolean(postsFetchedAt),
  };
};

export const buildQueryString = (query: GetPostsQuery) => {
  const { country, category, estado, localidad } = query;
  const queryArray = join(compact([country, category, estado, localidad]));
  return JSON.stringify(queryArray);
};
