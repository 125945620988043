import first from "lodash/first";
import compact from "lodash/compact";

import { createReducer } from "@reduxjs/toolkit";
import { AppState } from "types";
import { LOCATION_CHANGE } from "connected-next-router";
import { createPost, uploadMediaProgress } from "actions";
import { LocationChangeAction } from "connected-next-router/actions";

const initialState: AppState = {
  lastLocations: [],
  uploadMediaProgress: {},
};

export const appReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(LOCATION_CHANGE, (state, { payload }: LocationChangeAction) => {
      state.lastLocations = compact([
        payload.location.pathname,
        first(state.lastLocations),
      ]);
    })
    .addCase(createPost, (state) => {
      state.uploadMediaProgress = {};
    })
    .addCase(uploadMediaProgress, (state, { payload }) => {
      const { loaded, total, url, contentType } = payload;

      if (loaded === total) {
        delete state.uploadMediaProgress[url];
        return;
      }

      state.uploadMediaProgress[payload.url] = {
        loaded,
        total,
        contentType,
      };
    })
);
