// lodash
import endsWith from "lodash/endsWith";
import first from "lodash/first";
import isEmpty from "lodash/isEmpty";
import size from "lodash/size";

import {
  fork,
  select,
  put,
  all,
  call,
  takeEvery,
  takeLeading,
} from "redux-saga/effects";
import { successActionType, awaitResponse } from "utils";
import { mixpanelSagas } from "./mixpanelSagas";
import { LOCATION_CHANGE } from "connected-next-router";
import { logoutRequest, getCurrentUserRequest } from "requests";
import { selectModals } from "selectors";
import {
  closeModal,
  setCurrentUserId,
  initializeApp,
  resetStore,
} from "actions";
import { HTTP_CODES } from "consts";
import { ModalProps, ResponseAction, TUser } from "types";

function* initializeAppSaga() {
  // Get user data if cookie is present
  const { success } = yield call(awaitResponse, getCurrentUserRequest());
  const user: TUser | undefined = first(success?.payload?.data?.users);
  if (user) yield put(setCurrentUserId(user?.id));
}

function* resetStoreSaga() {
  yield put(resetStore());
}

function* removeCurrentUserOn401Saga({ type, payload }: ResponseAction) {
  const isRequestError = endsWith(type, "_Error");
  if (!isRequestError) return;

  if (payload.status === HTTP_CODES.UNAUTHORIZED) {
    yield call(resetStoreSaga);
  }
}

function* closeModalOnNavigationSaga() {
  const modals: Array<ModalProps> = yield select(selectModals);
  if (isEmpty(modals)) return;
  for (let i = 0; i < size(modals); i += 1) {
    yield put(closeModal());
  }
}

export function* appSagas() {
  yield all([
    fork(mixpanelSagas),
    takeLeading(initializeApp, initializeAppSaga),
    takeEvery("*", removeCurrentUserOn401Saga),
    takeEvery(LOCATION_CHANGE, closeModalOnNavigationSaga),
    takeEvery(successActionType(logoutRequest.type), resetStoreSaga),
  ]);
}
