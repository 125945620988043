// lodash
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import first from "lodash/first";
// Material Components
import Grid from "@mui/material/Grid";

import { useEffect } from "react";
import { useRouter } from "next/router";
import styled from "@emotion/styled";
import { openModal } from "actions";
import {
  Select,
  Autocomplete,
  useForm,
  Button,
  useDispatch,
  FormInput,
  Modal,
} from "common";
import { push } from "connected-next-router";
import {
  formKeys,
  useFormInputs,
  CATEGORIES_OPTIONS as _CATEGORIES_OPTIONS,
} from "./useFormInputs";
import { ESTADOS } from "consts";
import routes, { AnunciosQueryParams } from "routes";

export const CATEGORIES_OPTIONS = _CATEGORIES_OPTIONS;

export const ESTADOS_OPTIONS = () =>
  orderBy(
    map(ESTADOS, (estado, key) => ({
      label: estado.name,
      value: key,
    })),
    "label"
  );

export const LOCALIDADES_OPTIONS = (estadoKey: string) =>
  orderBy(
    map(ESTADOS[estadoKey].localidades, (localidad, key) => ({
      label: localidad.name,
      value: key,
    })),
    "label"
  );

export const DropdownFilter = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const formInputs = useFormInputs();
  const form = useForm(formInputs);
  const { getValuesObj, updateValues } = form;
  const valuesObj = getValuesObj();
  const estado = valuesObj[formKeys.estado];
  const category = valuesObj[formKeys.category];
  const localidad = valuesObj[formKeys.localidad];

  const {
    category: categoryKey,
    estado: estadoKey,
    localidad: localidadKey,
  } = router.query as unknown as AnunciosQueryParams;

  useEffect(() => {
    const update: FormInput = { key: formKeys.localidad, disabled: !estado };
    if (!estado) update.value = "";

    updateValues([update]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estado]);

  useEffect(() => {
    const updates: Array<FormInput> = [];
    // Don't allow the user to pick "No category"
    if (!category) {
      updates.push({
        key: formKeys.category,
        value: first(CATEGORIES_OPTIONS)!.value,
      });
    }

    // servicios virtuales is nationwide, so we disable
    // estado and localidad filters
    if (category === "servicios-virtuales") {
      updates.push(
        { key: formKeys.localidad, disabled: true, value: "" },
        { key: formKeys.estado, disabled: true, value: "" }
      );
    } else {
      updates.push(
        { key: formKeys.localidad, disabled: !estado },
        { key: formKeys.estado, disabled: false }
      );
    }

    updateValues(updates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  useEffect(() => {
    updateValues([
      { key: formKeys.category, value: categoryKey ?? "" },
      { key: formKeys.estado, value: estadoKey ?? "" },
      {
        key: formKeys.localidad,
        value: localidadKey ?? "",
        disabled: !estadoKey,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estadoKey, localidadKey, categoryKey]);

  const search = () => {
    dispatch(push(routes.anuncios({ category, estado, localidad })));
  };

  const addLocalidad = () => {
    dispatch(
      openModal({
        node: (
          <Modal
            title="Agregar Localidad"
            submitButton={{
              text: "Publicar Anuncio",
              onClick: () => dispatch(push(routes.publicarAnuncio())),
            }}
          >
            <p>Puedes agregar una nueva localidad al publicar un anuncio.</p>
            <p>
              Te regalaremos créditos si creas un anuncio verificado en la nueva
              localidad por tu ayuda al actualizar nuestras localidades.
            </p>
          </Modal>
        ),
      })
    );
  };

  return (
    <Container>
      <Grid container justifyContent="space-between" spacing={1}>
        <Grid item xs={6} md={3}>
          <Select
            form={form}
            formKey={formKeys.category}
            options={{ simple: CATEGORIES_OPTIONS }}
          />
        </Grid>

        <Grid item xs={6} md={3}>
          <Autocomplete
            form={form}
            formKey={formKeys.estado}
            options={ESTADOS_OPTIONS()}
            noOptionsText="Sin resultados"
          />
        </Grid>

        <Grid item xs={6} md={3}>
          <Autocomplete
            form={form}
            formKey={formKeys.localidad}
            options={estado ? LOCALIDADES_OPTIONS(estado) : []}
            enableAddNew={{ onAdd: addLocalidad }}
          />
        </Grid>

        <Grid item xs={6} md={3}>
          <ButtonContainer>
            <Button onClick={search} color="primary">
              Buscar
            </Button>
          </ButtonContainer>
        </Grid>
      </Grid>
    </Container>
  );
};

const Container = styled.div`
  width: 90%;
  max-width: 1000px;
`;
const ButtonContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  margin-top: -2px;

  button {
    width: 100%;
    height: 90%;
  }
`;
