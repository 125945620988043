import styled from "@emotion/styled";
import { STextField } from "common";
import { theme } from "theme";

export const ReadOnlyTextArea = ({
  value,
  className,
}: {
  value: string;
  className?: string;
}) => (
  <Label>
    <span>Descripción del anuncio</span>
    <CustomSTextField
      fullWidth
      multiline
      disabled
      value={value}
      className={className}
    />
  </Label>
);

const Label = styled.label`
  span {
    color: ${theme.palette.background.default};
  }
`;
const CustomSTextField = styled(STextField)`
  textarea {
    color: white !important;
    -webkit-text-fill-color: white !important;
  }

  .MuiFilledInput-root.Mui-disabled {
    background: transparent !important;
    font-size: 1.3rem;
  }

  ${theme.breakpoints.down("sm")} {
    .MuiFilledInput-root {
      font-size: 1.1rem;
    }
  }

  .MuiFilledInput-underline:before {
    border: none !important;
  }
`;
