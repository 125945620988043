import styled from "@emotion/styled";
import Link from "next/link";
import routes from "routes";
import { NEXT_PUBLIC_COMMIT_HASH } from "envVars";
import { theme } from "theme";

export const Footer = () => (
  <>
    <Container>
      <Link prefetch={false} href={routes.contacto()}>
        Contacto
      </Link>
      <Link prefetch={false} href={routes.politicaDePagos()}>
        Política de Pagos
      </Link>
      <Link prefetch={false} href={routes.legal()}>
        Legal
      </Link>
      <Link prefetch={false} href={routes.recomendaciones()}>
        Recomendaciones
      </Link>
    </Container>
    <span>
      v.
      {NEXT_PUBLIC_COMMIT_HASH ?? "dev"}
    </span>
  </>
);

const Container = styled.div`
  padding: 50px 0 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  p {
    margin: 0;
  }

  a {
    margin: 10px 20px;
    cursor: pointer;
    text-align: center;
    color: ${theme.palette.blue.main};
    text-decoration: underline;
  }
`;
