// lodash
import forEach from "lodash/forEach";

import { createReducer } from "@reduxjs/toolkit";
import { assignNormalizedValues } from "actions";
import { TransactionsState } from "types";

const initialState: TransactionsState = { byId: {} };

export const transactionsReducer = createReducer(initialState, (builder) =>
  builder.addCase(assignNormalizedValues, (state, { payload }) => {
    const { data } = payload;
    forEach(data?.transactions, (transaction) => {
      state.byId[transaction.id] = transaction;
    });
  })
);
