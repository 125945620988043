// Material Components
import ReportIcon from "@mui/icons-material/Report";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";

import { useState } from "react";
import styled from "@emotion/styled";
import { NEXT_PUBLIC_S3_ENDPOINT } from "envVars";
import { Loading } from "common";
import { mediaStatusEnum, IMAGES_SIZES, USER_MEDIA } from "consts";
import { verifiedImageSrc } from "res";
import { TMedia } from "types";
import { theme } from "theme";

export const buildImageUrl = (media: TMedia, size: keyof typeof IMAGES_SIZES) =>
  NEXT_PUBLIC_S3_ENDPOINT +
  "/" +
  USER_MEDIA +
  media.userId +
  "/" +
  media.id +
  ".jpeg." +
  size;

const StatusImg = styled.img`
  position: absolute;
  bottom: 2px;
  right: 2px;
  height: 30px;
  width: 30px;
`;
const IconContainer = styled.div<{ color: string; background: string }>`
  background: ${({ background }) => background};
  border-radius: 100%;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 2px;
  right: 2px;

  svg {
    width: 30px;
    height: auto;
    color: ${({ color }) => color};
  }
`;

export const statusIconMap: {
  [status in typeof mediaStatusEnum[number]]: any;
} = {
  verified: <StatusImg src={verifiedImageSrc} alt="verificada" />,
  approved: (
    <IconContainer
      color={theme.palette.blue.main}
      background={theme.palette.background.default}
    >
      <HourglassEmptyIcon />
    </IconContainer>
  ),
  pending: (
    <IconContainer
      color={theme.palette.blue.main}
      background={theme.palette.background.default}
    >
      <HourglassEmptyIcon />
    </IconContainer>
  ),
  rejected: (
    <IconContainer color={theme.palette.error.main} background="white">
      <ReportIcon />
    </IconContainer>
  ),
};

export const Image = ({
  media,
  size,
  alt,
  className,
  showStatus,
}: {
  media: TMedia;
  size: keyof typeof IMAGES_SIZES;
  alt: string;
  className?: string;
  showStatus?: boolean;
}) => {
  const [loaded, setLoaded] = useState(false);
  const source = buildImageUrl(media, size);

  const imgError = (val: any) => {
    const image = val.target;
    image.onerror = null;
    setTimeout(function () {
      image.src += "?" + +new Date();
    }, 3000);
  };

  return (
    <Container className={className}>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <SImg
        loaded={loaded}
        onLoad={() => {
          setLoaded(true);
        }}
        src={source}
        alt={alt}
        onError={imgError}
      />
      {showStatus && <Overlay>{statusIconMap[media.status]}</Overlay>}
      {!loaded && (
        <Overlay>
          <Loading size={30} />
        </Overlay>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;
const SImg = styled.img<{ loaded: boolean }>`
  ${({ loaded }) => (loaded ? "" : "visibility: hidden;")}
  height: 100%;
  width: 100%;
`;
const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
