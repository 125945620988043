// lodash
import get from "lodash/get";
import unset from "lodash/unset";
import set from "lodash/set";
import isEmpty from "lodash/isEmpty";

import produce from "immer";

import { RequestAction, ResponseAction, ApiState } from "types";

export const DEFAULT_CONTEXT = "defaultContext";

const initialState: ApiState = {
  isFetching: {},
  errors: {},
  pagination: {},
};

export const apiReducer = (
  state = initialState,
  action: RequestAction | ResponseAction
) => {
  const { meta, payload, type } = action;

  // All requests have action.meta.api,
  // All error and success responses have action.meta.requestStep
  if (!meta || (!("api" in meta) && !("requestStep" in meta))) {
    return state;
  }

  const context = meta.context || DEFAULT_CONTEXT;
  const requestStep = "requestStep" in meta ? meta.requestStep : "request";
  // if it's a response, we get `OGActionType`, if it's not there
  // we're dealing with a request and we can get `type`
  const actionType = "OGActionType" in meta ? meta?.OGActionType : type;

  return produce(state, (draftState: ApiState) => {
    // reset error for the request being made
    unset(draftState, ["errors", context, actionType]);

    if (requestStep !== "request") {
      if (requestStep === "error") {
        // Save all error data
        set(draftState, ["errors", context, actionType], action.payload);
      }
      if (requestStep === "success") {
        const pagination = payload?.data?.pagination;
        const key = ["pagination", context, actionType];
        if (pagination) set(draftState, key, pagination);
        // accumulate results count
        if (pagination?.page > 1) {
          set(
            draftState,
            key.concat(["results"]),
            pagination.results + get(state, key.concat(["results"]))
          );
        }
      }
      // if !request, fetching is done
      unset(draftState, ["isFetching", context, actionType]);
    } else {
      set(draftState, ["isFetching", context, actionType], true);
    }

    if (isEmpty(draftState.isFetching[context])) {
      delete draftState.isFetching[context];
    }
    if (isEmpty(draftState.errors[context])) {
      delete draftState.errors[context];
    }
  });
};
