// lodash
import map from "lodash/map";
// material components
import MuiBreadcrumbs from "@mui/material/Breadcrumbs";
// material icons
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import { ReactNode } from "react";
import styled from "@emotion/styled";
import Link from "next/link";
import { useRouter } from "next/router";
import routes, { AnunciosQueryParams } from "routes";
import { CATEGORIES, ESTADOS } from "consts";

export const Breadcrumbs = ({ className }: { className?: string }) => {
  const router = useRouter();

  const {
    category: categoryKey,
    estado: estadoKey,
    localidad: localidadKey,
  } = router.query as unknown as AnunciosQueryParams;

  // There is no urlQuery when we're approving posts as admins,
  // so there are no categoryKey, estadoKey, nor localidadKey
  if (!categoryKey) return null;

  const category = CATEGORIES[categoryKey];
  const estado = estadoKey ? ESTADOS[estadoKey] : null;
  const localidad =
    estado && localidadKey ? estado.localidades[localidadKey] : null;

  const links: Array<ReactNode> = [
    <Link
      key="servicio"
      href={routes.anuncios({ category: categoryKey })}
      prefetch={false}
    >
      {category}
    </Link>,
  ];

  if (categoryKey !== "servicios-virtuales") {
    if (estado) {
      links.push(
        <Link
          prefetch={false}
          key={estadoKey}
          href={routes.anuncios({
            category: categoryKey,
            estado: estadoKey,
          })}
        >
          {estado.name}
        </Link>
      );

      if (localidad) {
        links.push(
          <Link
            prefetch={false}
            key={localidadKey}
            href={routes.anuncios({
              category: categoryKey,
              estado: estadoKey,
              localidad: localidadKey,
            })}
          >
            {localidad.name}
          </Link>
        );
      }
    }
  }

  return (
    <SMuiBreadcrumbs
      className={className}
      separator={<NavigateNextIcon fontSize="small" color="primary" />}
      aria-label="breadcrumb"
    >
      <Link href={routes.home()} prefetch={false}>
        Inicio
      </Link>
      {map(links)}
    </SMuiBreadcrumbs>
  );
};

const SMuiBreadcrumbs = styled(MuiBreadcrumbs)`
  a {
    color: white;
    font-weight: bold;
    text-decoration: underline;
  }
`;
