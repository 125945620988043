// lodash
import map from "lodash/map";
import filter from "lodash/filter";
import startsWith from "lodash/startsWith";
import concat from "lodash/concat";
import isEmpty from "lodash/isEmpty";

import { all, call } from "redux-saga/effects";
import { postMediaRequest, uploadMediaRequest } from "requests";
import { awaitResponse } from "utils";
import { NewFile, TId, PostMediaResponse, ResponseAction } from "types";

export type UploadMediaSagaReturnType = {
  responses: Array<{ success: ResponseAction; error: ResponseAction }>;
  uploadedVideoIds: Array<TId>;
  uploadedImageIds: Array<TId>;
};

export function* uploadMediaSaga(
  newFiles: Array<NewFile>
): Generator<any, UploadMediaSagaReturnType, any> {
  if (isEmpty(newFiles))
    return {
      responses: [],
      uploadedImageIds: [],
      uploadedVideoIds: [],
    };

  const newImages = filter(newFiles, (newFile) =>
    startsWith(newFile.file.type, "image")
  );
  const newVideos = filter(newFiles, (newFile) =>
    startsWith(newFile.file.type, "video")
  );

  // Get signed urls and create media db entries
  const { success } = yield call(
    awaitResponse,
    postMediaRequest({
      images: map(newImages, ({ file }) => ({
        fileExtension: file.name.split(".").pop()!,
      })),
      videos: map(newVideos, ({ file }) => ({
        fileExtension: file.name.split(".").pop()!,
      })),
    })
  );

  const { imageUrls, videoUrls }: PostMediaResponse = success.payload.data;

  // Upload images
  const uploadedImageIds: Array<TId> = [];
  const uploadImageRequests = map(
    imageUrls,
    ({ presignedPost, mediaId }, index) => {
      uploadedImageIds.push(mediaId);
      return call(
        awaitResponse,
        uploadMediaRequest(presignedPost, newImages[index])
      );
    }
  );

  // Upload videos
  const uploadedVideoIds: Array<TId> = [];
  const uploadVideoRequests = map(
    videoUrls,
    ({ presignedPost, mediaId }, index) => {
      uploadedVideoIds.push(mediaId);
      return call(
        awaitResponse,
        uploadMediaRequest(presignedPost, newVideos[index])
      );
    }
  );

  const uploadMediaRequests = concat(uploadImageRequests, uploadVideoRequests);

  const responses: Array<{
    success: ResponseAction;
    error: ResponseAction;
  }> = yield all(uploadMediaRequests);

  const returnValue: UploadMediaSagaReturnType = {
    responses,
    uploadedVideoIds,
    uploadedImageIds,
  };

  return returnValue;
}
