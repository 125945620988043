// lodash
import includes from "lodash/includes";
import filter from "lodash/filter";

import { TId, RootState } from "types";

export const selectUserTransactions = (state: RootState, userId: TId) =>
  filter(state.transactions.byId, ({ receiverUserId, senderUserId }) =>
    includes([receiverUserId, senderUserId], userId)
  );
