import styled from "@emotion/styled";
import { theme } from "theme";

export const FormContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  max-width: 90%;
  margin: 0 auto;
  text-align: center;
  box-sizing: content-box;

  h1 {
    margin-bottom: 0;
  }

  .MuiTextField-root {
    width: 300px;
    max-width: 100%;
  }
`;

export const CustomLink = styled.span`
  color: ${theme.palette.primary.main};
  text-decoration: underline;
  cursor: pointer;
`;

export const WhatsAppHelp = styled.div`
  width: 300px;
  max-width: 90%;
  margin: 15px 0;

  a {
    color: lightgreen;
    text-decoration: underline;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
