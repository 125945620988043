// lodash
import join from "lodash/join";
import isNil from "lodash/isNil";
import compact from "lodash/compact";

import { APROBAR_ANUNCIOS } from "views/Admin/adminViews";
import { MIS_ANUNCIOS } from "views/User/userViews";
import { TId, Category } from "types";

export type AnunciosQueryParams = {
  estado?: string;
  localidad?: string;
  category: Category;
  postId?: string;
  index?: string;
};

const routes = {
  home: () => "/",
  favoritos: () => "/favoritos",
  login: () => "/auth",
  registro: () => "/auth/registro",
  olvideContrasena: () => "/auth/olvide-contrasena",
  logout: () => "/logout",
  userPosts: ({ userId }: { userId: TId }) => `/u/${userId}`,
  user: (params?: Array<string>) =>
    `/user/${params ? join(params, "/") : MIS_ANUNCIOS}`,
  admin: (params?: Array<string>) =>
    `/admin/${params ? join(params, "/") : APROBAR_ANUNCIOS}`,
  contacto: () => "/contacto",
  politicaDePagos: () => "/politica-de-pagos",
  legal: () => "/legal",
  recomendaciones: () => "/recomendaciones",
  publicarAnuncio: (params?: { postId: string }) =>
    `/anuncios/publicar/${params?.postId ?? ""}`,
  anuncios: ({
    category,
    estado,
    localidad,
    postId,
    index,
  }: AnunciosQueryParams) =>
    `/anuncios/${
      join(compact([category, estado, localidad, postId]), "/") +
      (isNil(index) ? "" : `?index=${index}`)
    }`,
};

export default routes;
