import createSagaMiddleware from "redux-saga";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { createRouterMiddleware, routerReducer } from "connected-next-router";
import { resetStore } from "actions";

import { appReducer } from "./app/reducer";
import { usersReducer } from "./users/reducer";
import { apiReducer } from "./api/reducer";
import { postsReducer } from "./posts/reducer";
import { mediaReducer } from "./media/reducer";
import { transactionsReducer } from "./transactions/reducer";
import { adminReducer } from "./admin/reducer";
import { uiReducer } from "./ui/reducer";

import rootSaga from "./root-saga";

const rootReducer = combineReducers({
  // persist on logout
  app: appReducer,
  api: apiReducer,
  ui: uiReducer,
  router: routerReducer,
  // reset on logout
  users: usersReducer,
  posts: postsReducer,
  media: mediaReducer,
  transactions: transactionsReducer,
  admin: adminReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

// We add this so we can reset the store with the action `resetStore`
const rootReducerWithReset: any = (state: RootState, action: any) => {
  if (action.type === resetStore.type) {
    // Do not delete org or api data
    const newState: Partial<RootState> = {
      app: state.app,
      api: state.api,
      ui: state.ui,
      router: state.router,
    };
    return rootReducer(newState as any, action);
  }
  return rootReducer(state, action);
};

const sagaMiddleware = createSagaMiddleware();
const routerMiddleware = createRouterMiddleware();

export const store = configureStore({
  reducer: rootReducerWithReset,
  middleware: [sagaMiddleware, routerMiddleware],
});

export type AppDispatch = typeof store.dispatch;

sagaMiddleware.run(rootSaga);

if (process.env.NODE_ENV !== "production" && module.hot) {
  module.hot.accept("./configure-store", () =>
    store.replaceReducer(rootReducer)
  );
}
