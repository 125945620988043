// Material Components
import Hidden from "@mui/material/Hidden";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
// Material Icons
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import UserFoundIcon from "@mui/icons-material/Person";

import { ReactNode } from "react";
import styled from "@emotion/styled";
import { TUser } from "types";
import { theme } from "theme";

export const DRAWER_WIDTH = 260;

export type DrawerContentProps = {
  setMobileOpen?: (mobileOpen: boolean) => void;
};

type Props = {
  setMobileOpen: (isOpen: boolean) => void;
  mobileOpen: boolean;
  drawerContent: ReactNode;
};

export const UserListItem = ({ user }: { user?: Omit<TUser, "password"> }) => {
  return (
    <UserListItemContainer>
      <ListItemIcon>
        <UserFoundIcon />
      </ListItemIcon>
      <ListItemText primary={user?.email} />
    </UserListItemContainer>
  );
};

export const Drawer = ({ setMobileOpen, mobileOpen, drawerContent }: Props) => (
  <DrawerContainer>
    <Hidden mdUp implementation="css">
      <MuiDrawer
        variant="temporary"
        open={mobileOpen}
        onClose={() => setMobileOpen(false)}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <DrawerContentContainer>{drawerContent}</DrawerContentContainer>
      </MuiDrawer>
      {!mobileOpen && (
        <MenuIconButtonContainer>
          <IconButton onClick={() => setMobileOpen(true)}>
            <MenuOpenIcon />
          </IconButton>
        </MenuIconButtonContainer>
      )}
    </Hidden>
    <Hidden mdDown implementation="css">
      <MuiDrawer variant="permanent" open>
        <DrawerContentContainer>{drawerContent}</DrawerContentContainer>
      </MuiDrawer>
    </Hidden>
  </DrawerContainer>
);

export const DrawerListItem = styled(ListItemButton)`
  &:hover {
    background: ${theme.palette.background.light} !important;
  }

  &.Mui-selected {
    background: ${theme.palette.background.light} !important;
  }
`;
const UserListItemContainer = styled(ListItem)`
  border-bottom: 2px solid ${theme.palette.blue.main};
`;
const DrawerContainer = styled.nav`
  position: relative;
  height: 100%;
  display: flex;

  .MuiDrawer-docked {
    height: 100%;
  }

  .MuiDrawer-paper {
    position: relative;
    background: transparent !important;
    border-radius: 0 10px 10px 0;
  }
`;
const DrawerContentContainer = styled.div`
  background: ${theme.palette.background.default};
  height: 100%;

  p,
  svg,
  span {
    color: ${theme.palette.blue.main};
  }
`;
const MenuIconButtonContainer = styled.div`
  position: fixed;
  top: 60px;
  left: 20px;
  background: white;
  border-radius: 100%;
  z-index: 1;

  svg {
    fill: ${theme.palette.background.default};
  }
`;
