// Material Icons
import ListIcon from "@mui/icons-material/List";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReceiptIcon from "@mui/icons-material/Receipt"; // Historial
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import LockIcon from "@mui/icons-material/Lock";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PhoneIcon from "@mui/icons-material/Phone";

import styled from "@emotion/styled";
import dynamic from "next/dynamic";
import { FC, ComponentClass } from "react";
import { whatsappImageSrc } from "res";

export const MIS_ANUNCIOS = "mis-anuncios";

export const viewMap: {
  [view: string]: { Component: FC | ComponentClass; title: string; Icon: FC };
} = {
  inicio: {
    Component: dynamic(() => import("./BackHome").then((mod) => mod.BackHome)),
    title: "Volver a inicio",
    Icon: ArrowBackIcon,
  },
  [MIS_ANUNCIOS]: {
    Component: dynamic(() =>
      import("./MisAnuncios").then((mod) => mod.MisAnuncios)
    ),
    title: "Mis Anuncios",
    Icon: ListIcon,
  },
  "mis-telefonos": {
    Component: dynamic(() =>
      import("./MisTelefonos").then((mod) => mod.MisTelefonos)
    ),
    title: "Mis Teléfonos",
    Icon: PhoneIcon,
  },
  creditos: {
    Component: dynamic(() => import("./Credito").then((mod) => mod.Credito)),
    title: "Créditos",
    Icon: AttachMoneyIcon,
  },
  "mi-galeria": {
    Component: dynamic(() =>
      import("./MiGaleria").then((mod) => mod.MiGaleria)
    ),
    title: "Mi Galería",
    Icon: PhotoLibraryIcon,
  },
  "fotos-verificacion": {
    Component: dynamic(() =>
      import("./VerificationKit").then((mod) => mod.VerificationKit)
    ),
    title: "Fotos de Verificación",
    Icon: CheckCircleIcon,
  },
  historial: {
    Component: dynamic(() =>
      import("./HistorialCreditos").then((mod) => mod.HistorialCreditos)
    ),
    title: "Historial de créditos",
    Icon: ReceiptIcon,
  },
  support: {
    Component: dynamic(() => import("./Support").then((mod) => mod.Support)),
    title: "Soporte",
    Icon: () => <Whatsapp src={whatsappImageSrc} alt="whatsapp" />,
  },
  "borrar-cuenta": {
    Component: dynamic(() =>
      import("./BorrarCuenta").then((mod) => mod.BorrarCuenta)
    ),
    title: "Borrar Cuenta",
    Icon: DeleteForeverIcon,
  },
  "cambiar-contrasena": {
    Component: dynamic(() =>
      import("./CambiarContrasena").then((mod) => mod.CambiarContrasena)
    ),
    title: "Cambiar Contraseña",
    Icon: LockIcon,
  },
  "cerrar-sesion": {
    Component: dynamic(() =>
      import("./CerrarSesion").then((mod) => mod.CerrarSesion)
    ),
    title: "Cerrar Sesión",
    Icon: ExitToAppIcon,
  },
};

const Whatsapp = styled.img`
  width: 24px;
  height: 24px;
`;
