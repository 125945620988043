// lodash
import first from "lodash/first";
import isEmpty from "lodash/isEmpty";

import { useEffect } from "react";
import { NEXT_PUBLIC_ADMIN_WHATSAPP } from "envVars";
import {
  Button,
  useCountry,
  useSelector,
  useDispatch,
  useForm,
  TextField,
  Checkbox,
  REQUIRED_FIELD_ERROR,
  Spacer,
  InputError,
} from "common";
import Link from "next/link";
import { mixpanelSuccessfulLogin } from "mixpanelEventActions";
import styled from "@emotion/styled";
import { push } from "connected-next-router";
import routes from "routes";
import {
  FlexRow,
  WhatsAppHelp,
  CustomLink,
  FormContainer,
} from "../components";
import { useFormInputs, formKeys } from "./useFormInputs";
import { setCurrentUserId } from "actions";
import { whatsappHrefBuilder, isValidEmail } from "utils";
import { selectIsFetching, selectCurrentUser, selectError } from "selectors";
import { loginRequest } from "requests";
import { AssignNormalizedValuesPayload } from "types";

export const INVALID_PHONE_ERROR = "Este teléfono no es válido";
export const INVALID_EMAIL_ERROR = "Este email no es válido";

export const Login = () => {
  const dispatch = useDispatch();
  const country = useCountry();
  const user = useSelector(selectCurrentUser);
  const formInputs = useFormInputs();
  const form = useForm(formInputs);
  const { getValuesObj, updateValues } = form;
  const valuesObj = getValuesObj();
  const isFetching = useSelector((state) =>
    selectIsFetching(state, { request: loginRequest }),
  );
  const loginError = useSelector((state) =>
    selectError(state, { request: loginRequest }),
  );

  useEffect(() => {
    if (user) {
      if (user.admin) {
        dispatch(push(routes.admin()));
      } else {
        dispatch(push(routes.user()));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const showPassword = valuesObj[formKeys.showPassword];
  useEffect(() => {
    if (showPassword) {
      updateValues([{ key: formKeys.password, type: undefined }]);
    } else {
      updateValues([{ key: formKeys.password, type: "password" }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPassword]);

  const submitLogin = () => {
    const errors = [];

    const email = valuesObj[formKeys.email];
    if (!email) {
      errors.push({ key: formKeys.email, error: REQUIRED_FIELD_ERROR });
    } else if (!isValidEmail(email)) {
      errors.push({ key: formKeys.email, error: INVALID_EMAIL_ERROR });
    }

    const password = valuesObj[formKeys.password];
    if (!password) {
      errors.push({ key: formKeys.password, error: REQUIRED_FIELD_ERROR });
    }

    if (!isEmpty(errors)) {
      updateValues(errors);
      return;
    }

    dispatch(
      loginRequest(
        { email, password, country: country.code },
        {
          onResponse: {
            successCallback: (data: AssignNormalizedValuesPayload) => {
              const user = first(data.users);
              dispatch(setCurrentUserId(user?.id));
              dispatch(mixpanelSuccessfulLogin());
            },
          },
        },
      ),
    );
  };

  return (
    <FormContainer>
      <h1>Hola</h1>
      <p>Inicia sesión con tu cuenta</p>
      <TextField formKey={formKeys.email} form={form} onEnter={submitLogin} />
      <Spacer height={20} />
      <TextField
        formKey={formKeys.password}
        form={form}
        onEnter={submitLogin}
      />
      <Checkbox formKey={formKeys.showPassword} form={form} />
      <ButtonContainer>
        <Button onClick={submitLogin} loading={isFetching} color="primary">
          Iniciar Sesión
        </Button>
      </ButtonContainer>
      {loginError && (
        <>
          <InputError>{loginError.data?.message}</InputError>
          <WhatsAppHelp>
            ¿Necesitas ayuda? Contáctanos por{" "}
            <a
              rel="noreferrer"
              target="_blank"
              href={whatsappHrefBuilder({
                message: "Hola, necesito ayuda con mi registro.",
                phoneCountryCode: "52",
                phoneNumber: NEXT_PUBLIC_ADMIN_WHATSAPP,
              })}
            >
              WhatsApp en este link.
            </a>
          </WhatsAppHelp>
        </>
      )}
      <FlexRow>
        <p>¿No tienes cuenta?</p>
        <Link href={routes.registro()} passHref>
          <CustomLink>¡Regístrate gratis!</CustomLink>
        </Link>
      </FlexRow>
      <Spacer height={10} />
      <Link href={routes.olvideContrasena()} passHref>
        <CustomLink>¿Olividaste tu contraseña?</CustomLink>
      </Link>
    </FormContainer>
  );
};

const ButtonContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 30px;
`;
