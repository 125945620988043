// lodash
import uniqueId from "lodash/uniqueId";
import remove from "lodash/remove";

import { createReducer, Draft } from "@reduxjs/toolkit";
import {
  openSnackbar,
  closeSnackbar,
  setFilters,
  openModal,
  closeModal,
} from "actions";
import { SnackbarOptions, UIState } from "types";

const initialState: UIState = {
  modals: [],
  filters: {},
  snackbars: [],
};

export const uiReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(openModal, (state, { payload }) => {
      state.modals.push(payload);
    })
    .addCase(closeModal, (state) => {
      state.modals.pop();
    })
    .addCase(setFilters, (state, { payload }) => {
      state.filters = payload;
    })
    .addCase(openSnackbar, (state, { payload }) => {
      state.snackbars.push({
        ...(payload as Draft<SnackbarOptions>),
        key: uniqueId("snackbar-"),
      });
    })
    .addCase(closeSnackbar, (state, { payload: { key } }) => {
      // Remove mutates the collection
      remove(state.snackbars, { key });
    })
);
