// Material Icons
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
// lodash
import includes from "lodash/includes";
import map from "lodash/map";

import styled from "@emotion/styled";
import { Image, Video, useIsMobile, useDispatch } from "common";
import { openModal } from "actions";
import { TMedia } from "types";
import { GalleryModal } from "./GalleryModal";
import { theme } from "theme";

export type SelectableGallery = {
  selectedMedia: Array<TMedia>;
  handleSelectMedia: (media: TMedia) => void;
};

export const Gallery = ({
  media,
  isReview,
  selectable,
  showStatus,
  showDelete,
  largeMedia,
}: {
  media: Array<TMedia>;
  isReview?: {
    approval?: boolean;
    verification?: boolean;
  };
  showStatus?: boolean;
  showDelete?: boolean;
  selectable?: SelectableGallery;
  largeMedia?: boolean; // used when copying images for tweets
}) => {
  const dispatch = useDispatch();
  const selectedMediaIds = map(selectable?.selectedMedia, "id");
  const isMobile = useIsMobile();

  const handleMediaClick = (selectedMedia: TMedia) => {
    if (selectable) {
      selectable.handleSelectMedia(selectedMedia);
    } else {
      dispatch(
        openModal({
          fullscreen: "always",
          node: (
            <GalleryModal
              selectedMedia={selectedMedia}
              isReview={isReview}
              mediaIds={map(media, "id")}
              showStatus={showStatus}
              showDelete={showDelete}
            />
          ),
        })
      );
    }
  };

  return (
    <Container>
      {map(media, (mediaItem) => (
        <MediaContainer
          key={mediaItem.id}
          onClick={() => handleMediaClick(mediaItem)}
        >
          <AspectRatio />
          {mediaItem.type === "image" ? (
            <SImage
              media={mediaItem}
              size={largeMedia ? "large" : isMobile ? "thumbnail" : "small"}
              alt="escort thumbnail"
              showStatus={showStatus}
            />
          ) : (
            <Video media={mediaItem} showStatus={showStatus} isPreview />
          )}
          {includes(selectedMediaIds, mediaItem.id) && (
            <Selected>
              <SelectedIconContainer>
                <CheckCircleOutlineIcon />
              </SelectedIconContainer>
            </Selected>
          )}
        </MediaContainer>
      ))}
    </Container>
  );
};

const Container = styled.div`
  max-width: 935px;
  width: 100%;
  margin: 0 auto;

  display: grid;
  grid-template-columns: repeat(auto-fill, 32%);
  justify-content: space-between;
`;
const MediaContainer = styled.div`
  cursor: pointer;
  position: relative;
  margin-top: 7%;
  border-radius: 4px;
  overflow: hidden;
`;
const SImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  img {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
`;
const AspectRatio = styled.div`
  margin-top: 100%; // 1:1 Aspect ratio
`;
const Selected = styled.div`
  background: rgba(255, 255, 255, 0.3);
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;
const SelectedIconContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  height: 25%;
  width: 25%;

  svg {
    background: ${theme.palette.primary.main};
    border-radius: 100%;
    width: 100%;
    height: 100%;
    color: white;
  }
`;
