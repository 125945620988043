import { NEXT_PUBLIC_S3_ENDPOINT } from "envVars";

export * from "./Top";
export * from "./VisaSvg";
export * from "./MastercardSvg";
export * from "./MaestroSvg";
export * from "./AmericanExpressSvg";
export * from "./OxxoSvg";

// nextjs is not adding any cache headers
// to elements in the public folder, so we're hosting
// them in s3
const s3Static = (filename: string) =>
  `${NEXT_PUBLIC_S3_ENDPOINT}/static/${filename}`;

export const instruccionesVerificacion = s3Static(
  "instrucciones-verificacion.pdf"
);
export const leyMexa = s3Static("ley-mexa.pdf");
export const verifiedImageSrc = s3Static("verified.png");
export const cameraImageSrc = s3Static("camera.png");
export const whatsappImageSrc = s3Static("whatsapp.png");
export const escorts = s3Static("escorts.png");
export const gigolos = s3Static("gigolos.png");
export const escortsGay = s3Static("escorts-gay.png");
export const masajesEroticos = s3Static("masajes-eroticos.png");
export const serviciosVirtuales = s3Static("servicios-virtuales.png");
export const trans = s3Static("trans.png");
