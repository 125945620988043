// Material Components
import ReportIcon from "@mui/icons-material/Report";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";

import { verifiedImageSrc } from "res";
import { mediaStatusEnum } from "consts";
import styled from "@emotion/styled";
import { TMedia } from "types";
import { theme } from "theme";

const StatusContainer = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 10px;
  }
`;
const IconContainer = styled.div<{ background: string; color: string }>`
  background: ${({ background }) => background};
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${({ color }) => color};
    height: 30px;
    width: auto;
  }
`;
const StatusImg = styled.img`
  height: 30px;
  width: 30px;
`;

export const statusMap: {
  [status in typeof mediaStatusEnum[number]]: any;
} = {
  verified: (
    <StatusContainer>
      <StatusImg src={verifiedImageSrc} alt="verificada" />
      <p>Verificado</p>
    </StatusContainer>
  ),
  approved: (
    <StatusContainer>
      <IconContainer
        color={theme.palette.blue.main}
        background={theme.palette.background.default}
      >
        <HourglassEmptyIcon />
      </IconContainer>
      <p>En proceso de verificación</p>
    </StatusContainer>
  ),
  pending: (
    <StatusContainer>
      <IconContainer
        color={theme.palette.blue.main}
        background={theme.palette.background.default}
      >
        <HourglassEmptyIcon />
      </IconContainer>
      <p>En proceso de verificación</p>
    </StatusContainer>
  ),
  rejected: (
    <StatusContainer>
      <IconContainer color={theme.palette.error.main} background="white">
        <ReportIcon />
      </IconContainer>
      <p>No se pudo verificar</p>
    </StatusContainer>
  ),
};

export const Status = ({ media }: { media: TMedia }) => {
  if (!media) return null;
  return statusMap[media.status];
};
