import styled from "@emotion/styled";
import { SVG_HEIGHT, RawCardBrandsSvg } from "./raw-svg-card-brands";

export const AmericanExpressSvg = () => (
  <Container>
    <AmericanExpress />
  </Container>
);

const Container = styled.div`
  position: relative;
  height: ${SVG_HEIGHT};
  overflow: hidden;
  width: 81px;
`;
const AmericanExpress = styled(RawCardBrandsSvg)`
  position: absolute;
  height: 100%;
  width: auto;
  left: -222px;
`;
