// lodash
import forEach from "lodash/forEach";

import { createRequest } from "utils";
import { media, mediaItem } from "store/api/endpoints";
import { PresignedPost, NewFile, TId, PostMediaBody } from "types";

export const uploadMediaRequestActionType = "uploadMediaRequest";
export const uploadMediaRequest = (
  { url, fields }: PresignedPost,
  newFile: NewFile
) => {
  const formData = new FormData();

  forEach(fields, (value, key) => {
    formData.append(key, value);
  });

  formData.append("Content-Type", newFile.file.type);
  formData.append("file", newFile.file);

  return createRequest<FormData>(uploadMediaRequestActionType, {
    method: "post",
    endpoint: () => url,
    overrideHeaders: {
      "Content-Type": "multipart/form-data",
    },
  })(formData, { context: newFile.url });
};

export const postMediaRequest = createRequest<PostMediaBody>(
  "postMediaRequest",
  {
    method: "post",
    endpoint: media,
  }
);

export const getUserMediaRequest = createRequest("getUserMediaRequest", {
  method: "get",
  endpoint: media,
});

export const deleteMediaRequest = createRequest<{ mediaId: TId }>(
  "deleteMediaRequest",
  {
    method: "delete",
    endpoint: mediaItem,
  }
);
