import styled from "@emotion/styled";
import { useRouter } from "next/router";
import { Spacer } from "common";
import { ESTADOS, CATEGORIES } from "consts";
import { AnunciosQueryParams } from "routes";
import { TPost } from "types";

export const Location = ({ post }: { post?: TPost }) => {
  const router = useRouter();

  const { category: categoryKey, estado: estadoKey } =
    router.query as unknown as AnunciosQueryParams;

  if (!post) return <Spacer height={24} />;

  const category = CATEGORIES[post.category];
  const estado = ESTADOS[post.estado];
  const localidadName = estado.localidades[post.localidad].name;

  // Depending on the route, we have different keys available
  const getText = () => {
    if (post.category === "servicios-virtuales") return category;
    // If !categoryKey, we're looking at a users' "MisAnuncios"
    if (!categoryKey) return `${category} - ${localidadName}, ${estado.name}`;
    // If !categoryKey, we're looking at ListaPublicaciones for a country
    if (!estadoKey) return estado.name;
    // we're looking at ListaPublicaciones for a estado or localidad
    return localidadName;
  };

  return <Text>{getText()}</Text>;
};

const Text = styled.p`
  text-align: center;
  margin: 0;
  margin-top: 5px;
`;
