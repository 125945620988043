// Material Components
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";

import { useState } from "react";
import styled from "@emotion/styled";
import { TMedia } from "types";
import { USER_MEDIA } from "consts";
import { Loading } from "common";
import { statusIconMap } from "./Image";
import { NEXT_PUBLIC_S3_ENDPOINT } from "envVars";
import { theme } from "theme";

export const buildVideoUrl = (media: TMedia) =>
  NEXT_PUBLIC_S3_ENDPOINT +
  "/" +
  USER_MEDIA +
  media.userId +
  "/" +
  media.id +
  ".mp4";

export const Video = ({
  media,
  showStatus,
  isPreview,
}: {
  media: TMedia;
  showStatus?: boolean;
  isPreview?: boolean;
}) => {
  const [loaded, setLoaded] = useState(false);
  const source = buildVideoUrl(media);

  return (
    <Container loaded={loaded} isPreview={Boolean(isPreview)}>
      <video
        preload="metadata"
        height="100%"
        onLoadedMetadata={() => setLoaded(true)}
        controls={!isPreview}
      >
        <source src={source + "#t=0.1"} type="video/mp4" />
      </video>
      {!loaded && <Loading />}
      {loaded && isPreview && (
        <Overlay>
          <PlayCircleFilledIcon />
        </Overlay>
      )}
      {showStatus && <SOverlay>{statusIconMap[media.status]}</SOverlay>}
    </Container>
  );
};

const Container = styled.div<{ loaded: boolean; isPreview: boolean }>`
  ${({ isPreview }) =>
    isPreview
      ? `
  position: absolute;
  top: 0;
  bottom: 0;
  left: -16%;
  `
      : ""}

  video {
    position: relative;
    z-index: 0;
    ${({ loaded }) => (loaded ? "" : "visibility: hidden;")}
  }
`;
const Overlay = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    color: white;
    background: ${theme.palette.background.default};
    border-radius: 100%;
    height: auto;
    width: 80px;
    max-width: 30%;
  }
`;
const SOverlay = styled(Overlay)`
  right: 14%;
  bottom: 2px;
`;
