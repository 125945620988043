import { createRequest } from "utils";
import {
  adminPosts,
  adminPost,
  adminMedia,
  adminLoginAs,
  adminMediaPending,
  adminTransferCredits,
} from "store/api/endpoints";
import {
  TId,
  AdminPatchMediaBody,
  PatchPostBody,
  AdminLoginAsPostBody,
  PostAdminTransferCreditsBody,
} from "types";

export const adminGetPostsToApproveRequest = createRequest(
  "adminGetPostsToApproveRequest",
  {
    method: "get",
    endpoint: adminPosts,
  }
);

export const adminLoginAsPostRequest = createRequest<AdminLoginAsPostBody>(
  "adminLoginAsPostRequest",
  {
    method: "post",
    endpoint: adminLoginAs,
  }
);

export const adminPatchPostRequest = createRequest<PatchPostBody>(
  "adminPatchPostRequest",
  {
    method: "patch",
    endpoint: adminPost,
  }
);

export const adminDeleteMediaRequest = createRequest<{ mediaId: TId }>(
  "adminDeleteMediaRequest",
  {
    method: "delete",
    endpoint: adminMedia,
    transformResponse: (_, { mediaId }) => ({ mediaId }),
  }
);

export const adminDeletePostRequest = createRequest<{ postId: TId }>(
  "adminDeletePostRequest",
  {
    method: "delete",
    endpoint: adminPost,
  }
);

export const adminPatchMediaRequest = createRequest<AdminPatchMediaBody>(
  "adminPatchMediaRequest",
  {
    method: "patch",
    endpoint: adminMedia,
  }
);

export const adminGetPendingVerifyMediaRequest = createRequest(
  "adminGetPendingVerifyMediaRequest",
  {
    method: "get",
    endpoint: adminMediaPending,
  }
);

export const adminTransferCreditsRequest =
  createRequest<PostAdminTransferCreditsBody>("adminTranferCreditsRequest", {
    method: "post",
    endpoint: adminTransferCredits,
  });
