import { FormInput } from "common";

export const formKeys = {
  email: "email",
  password: "password",
  showPassword: "showPassword",
};

export const useFormInputs = (): Array<FormInput> => {
  const email = {
    key: formKeys.email,
    value: "",
    label: "Email",
    required: true,
    type: "email",
    forceLowerCase: true,
  };

  const password = {
    key: formKeys.password,
    value: "",
    label: "Contraseña",
    required: true,
    type: "password",
  };

  const showPassword = {
    key: formKeys.showPassword,
    value: false,
    label: "Mostar contraseña",
  };

  return [email, password, showPassword];
};
