import { useState, useEffect } from "react";

type VisibilityProps = { hiddenProp: string; visibilityChangeEvent: string };

// Property names differs browser to browser
// https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
const getBrowserVisibilityProps = (): undefined | VisibilityProps => {
  if (typeof window === "undefined") return;

  let hiddenProp: string;
  let visibilityChangeEvent: string;

  if (typeof document?.hidden !== "undefined") {
    // Opera 12.10 and Firefox 18 and later support
    hiddenProp = "hidden";
    visibilityChangeEvent = "visibilitychange";
    // @ts-ignore
  } else if (typeof document?.msHidden !== "undefined") {
    hiddenProp = "msHidden";
    visibilityChangeEvent = "msvisibilitychange";
    // @ts-ignore
  } else if (typeof document?.webkitHidden !== "undefined") {
    hiddenProp = "webkitHidden";
    visibilityChangeEvent = "webkitvisibilitychange";
  }

  return {
    hiddenProp: hiddenProp!,
    visibilityChangeEvent: visibilityChangeEvent!,
  };
};

const visibilityProps = getBrowserVisibilityProps();

export const usePageVisibility = () => {
  const [isVisible, setIsVisible] = useState<boolean | null>(true);

  const onVisibilityChange = () => {
    const hidden = (document as any)[visibilityProps!.hiddenProp];
    setIsVisible(!hidden);
  };

  useEffect(() => {
    if (!visibilityProps) return;
    document.addEventListener(
      visibilityProps.visibilityChangeEvent,
      onVisibilityChange
    );

    return () => {
      document.removeEventListener(
        visibilityProps.visibilityChangeEvent,
        onVisibilityChange
      );
    };
  }, []);

  return {
    isVisible,
  };
};
