// Material Icons
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import ReportIcon from "@mui/icons-material/Report";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
// lodash
import find from "lodash/find";

import styled from "@emotion/styled";
import { Button, useSelector } from "common";
import { selectCurrentUser, selectUserMedia } from "selectors";
import { theme } from "theme";
import { verifiedImageSrc } from "res";
import { mediaStatusEnum } from "consts";
import { TMedia } from "types";

type Props = {
  onThisImageRemove: () => void;
  mediaSrc: string;
  file?: File;
  media?: TMedia;
  mediaType: "video" | "image";
};

const Legend = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 10px;
  }
`;
const LegendImg = styled.img`
  height: 30px;
  width: 30px;
`;
const IconContainer = styled.div<{ background: string; color: string }>`
  background: ${({ background }) => background};
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${({ color }) => color};
    height: 30px;
    width: auto;
  }
`;

const statusMap: { [status in typeof mediaStatusEnum[number]]: any } = {
  pending: (
    <Legend>
      <IconContainer
        color={theme.palette.blue.main}
        background={theme.palette.background.default}
      >
        <HourglassEmptyIcon />
      </IconContainer>
      <p>En verificación</p>
    </Legend>
  ),
  verified: (
    <Legend>
      <LegendImg src={verifiedImageSrc} alt="verificada" />
      <p>Verificado</p>
    </Legend>
  ),
  rejected: (
    <Legend>
      <IconContainer color={theme.palette.error.main} background="white">
        <ReportIcon />
      </IconContainer>
      <p>No verificado</p>
    </Legend>
  ),
  approved: null,
};

export const PostMediaPreview = ({
  onThisImageRemove,
  mediaSrc,
  file,
  mediaType,
  media,
}: Props) => {
  const user = useSelector(selectCurrentUser);
  const userMedia = useSelector((state) => selectUserMedia(state, user!.id));
  const hasVerificationKit = Boolean(
    find(userMedia, { verificationKit: true })
  );
  const isNewFile = Boolean(file);

  return (
    <MediaContainer>
      {mediaType === "image" ? (
        <ImgPreview src={mediaSrc} />
      ) : (
        <VideoContainer newFile={Boolean(file)}>
          <video height={200} preload="metadata">
            <source
              src={mediaSrc + "#t=0.1"}
              type={file?.type ?? "video/mp4"}
            />
          </video>
          <PlayIconContainer>
            <PlayCircleFilledIcon />
          </PlayIconContainer>
        </VideoContainer>
      )}
      <MediaButtonsContainer>
        {isNewFile && (
          <NewFileIndicator>
            <CloudUploadIcon />
            <p>Nuevo archivo</p>
          </NewFileIndicator>
        )}
        {media && hasVerificationKit && statusMap[media.status]}

        <DeleteButton color="error" onClick={onThisImageRemove}>
          Remover
        </DeleteButton>
      </MediaButtonsContainer>
    </MediaContainer>
  );
};

const MediaContainer = styled.div`
  display: flex;
  margin: 10px 0;
  border: 1px solid ${theme.palette.primary.main};
  padding: 10px;
  border-radius: 5px;
  height: 220px;
  background: #2c2c2c;
`;
const ImgPreview = styled.img`
  width: 120px;
  height: 200px;
  object-fit: contain;
`;
const VideoContainer = styled.div<{ newFile: boolean }>`
  width: 120px;
  height: 200px;
  position: relative;
  overflow: hidden;

  ${({ newFile }) =>
    !newFile &&
    `
  video {
    position: absolute;
    left: -73px;
  }
  `}
`;
const MediaButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  justify-content: center;
`;
const DeleteButton = styled(Button)`
  width: 100px;
`;
const NewFileIndicator = styled.div`
  display: flex;
  color: ${theme.palette.blue.main};
  align-items: center;

  svg {
    margin-right: 7px;
  }
`;
export const PlayIconContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;

  svg {
    color: white;
    background: ${theme.palette.background.default};
    border-radius: 100%;
    height: auto;
    width: 80px;
    max-width: 35%;
  }
`;
