import styled from "@emotion/styled";
import { SVG_HEIGHT, RawCardBrandsSvg } from "./raw-svg-card-brands";

export const VisaSvg = () => (
  <Container>
    <Visa />
  </Container>
);

const Container = styled.div`
  position: relative;
  height: ${SVG_HEIGHT};
  overflow: hidden;
  width: 68px;
`;
const Visa = styled(RawCardBrandsSvg)`
  position: absolute;
  height: 100%;
  width: auto;
  left: -1px;
`;
