import { createRequest } from "utils";
import { createAction } from "@reduxjs/toolkit";
import { PostsState, TId, FavouritesPayload, CreatePostPayload } from "types";

export const createPost = createRequest<CreatePostPayload>("createPost", {
  method: "submit",
});

export const updateFavouritePosts = createAction<FavouritesPayload>(
  "updateFavouritePosts"
);

export const setCurrentQueryResults = createAction<Array<TId>>(
  "setCurrentQueryResults"
);

export const setPostBackLink = createAction<PostsState["backLink"]>(
  "setPostBackLink"
);
