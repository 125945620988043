import { NEXT_PUBLIC_COUNTRY_CODE } from "envVars";
import { Category } from "types";
import { estados } from "./dynamicImport";

export * from "../../../shared/consts";
export * from "../../../lambdas/consts";

export const ESTADOS: {
  [estadoKey: string]: {
    name: string;
    localidades: {
      [localidadKey: string]: {
        name: string;
      };
    };
  };
} = estados;

export const CATEGORIES: {
  [category in Category]: string;
} = {
  escorts: "Escorts",
  "escorts-gay": NEXT_PUBLIC_COUNTRY_CODE === "ES" ? "Chaperos" : "Escorts Gay",
  "masajes-eroticos": "Masajes Eróticos",
  gigolos: "Gigolos",
  travestis: "Trans y Travestis",
  "servicios-virtuales": "Servicios Virtuales",
};
