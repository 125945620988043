import { createRequest } from "utils";
import {
  postTop,
  postStatus,
  currentUserPosts,
  userPosts,
  posts,
  post,
  postViewCount,
  postFeatured,
  localidad,
} from "store/api/endpoints";
import {
  TId,
  PatchPostTopBody,
  PatchPostFeaturedBody,
  GetPostsQuery,
  PostPostBody,
  PatchPostStatusBody,
  PatchPostBody,
  PostLocalidadBody,
  GetUserPostsQuery,
} from "types";

export const createPostRequest = createRequest<PostPostBody>(
  "createPostRequest",
  {
    method: "post",
    endpoint: posts,
  }
);

export const getPostsRequest = createRequest<GetPostsQuery>("getPostsRequest", {
  method: "get",
  endpoint: posts,
  transformResponse: (data, payload) => ({
    ...data,
    query: payload,
  }),
});

export const getPostRequest = createRequest<{ postId: TId }>("getPostRequest", {
  method: "get",
  endpoint: post,
});

export const deletePostRequest = createRequest<{ postId: TId }>(
  "deletePostRequest",
  {
    method: "delete",
    endpoint: post,
  }
);

export const incrementPostViewCountRequest = createRequest<{ postId: TId }>(
  "incrementPostViewCountRequest",
  {
    method: "post",
    endpoint: postViewCount,
  }
);

export const getCurrentUserPostsRequest = createRequest(
  "getCurrentUserPostsRequest",
  {
    method: "get",
    endpoint: currentUserPosts,
  }
);

export const getUserPostsRequest = createRequest<GetUserPostsQuery>(
  "getUserPostsRequest",
  {
    method: "get",
    endpoint: userPosts,
  }
);

export const patchPostStatusRequest = createRequest<PatchPostStatusBody>(
  "patchPostStatusRequest",
  {
    method: "patch",
    endpoint: postStatus,
  }
);

export const patchPostTopRequest = createRequest<PatchPostTopBody>(
  "patchPostTopRequest",
  {
    method: "patch",
    endpoint: postTop,
  }
);

export const patchPostFeaturedRequest = createRequest<PatchPostFeaturedBody>(
  "patchPostFeaturedRequest",
  {
    method: "patch",
    endpoint: postFeatured,
  }
);

export const patchPostRequest = createRequest<PatchPostBody>(
  "patchPostRequest",
  {
    method: "patch",
    endpoint: post,
  }
);

export const postLocalidadRequest = createRequest<PostLocalidadBody>(
  "postLocalidadRequest",
  {
    method: "post",
    endpoint: localidad,
  }
);
