import { all, fork } from "redux-saga/effects";

import { appSagas } from "./app/sagas";
import { usersSagas } from "./users/sagas";
import { apiSagas } from "./api/sagas";
import { postsSagas } from "./posts/sagas";

export default function* rootSaga() {
  yield all([
    fork(appSagas),
    fork(apiSagas),
    fork(postsSagas),
    fork(usersSagas),
  ]);
}
