import styled from "@emotion/styled";
import { SVG_HEIGHT } from "./raw-svg-card-brands";
import { RawOxxoSvg } from "./raw-svg-oxxo";

export const OxxoSvg = () => (
  <Container>
    <Oxxo />
  </Container>
);

const Container = styled.div`
  position: relative;
  height: ${SVG_HEIGHT};
  overflow: hidden;
  width: 90px;
`;
const Oxxo = styled(RawOxxoSvg)`
  position: absolute;
  height: 100%;
  width: auto;
  left: 2px;
`;
