import { createRequest } from "utils";
import {
  logout,
  login,
  currentUser,
  registrationStart,
  registrationVerifyPhone,
  registrationCreateUser,
  registrationVerifyEmail,
  userVerificationKit,
  userUpdatePassword,
  userForgotPassword,
  paymentCheckoutSession,
  userPhoneNumbers,
  verifyUserPhoneNumber,
} from "store/api/endpoints";
import {
  PostUserVerificationKitBody,
  PostLoginBody,
  PostPhoneNumberBody,
  PostCreateUserBody,
  DeletePhoneNumberBody,
  PostVerifyEmailBody,
  PostVerifyPhoneNumberBody,
  PostForgotPasswordBody,
  PatchUserPasswordBody,
  PostPaymentCheckoutSessionBody,
} from "types";

export const addPhoneNumberRequest = createRequest<PostPhoneNumberBody>(
  "addPhoneNumberRequest",
  {
    method: "post",
    endpoint: userPhoneNumbers,
  }
);

export const verifyPhoneNumberRequest =
  createRequest<PostVerifyPhoneNumberBody>("verifyPhoneNumberRequest", {
    method: "post",
    endpoint: verifyUserPhoneNumber,
  });

export const deletePhoneNumberRequest = createRequest<DeletePhoneNumberBody>(
  "deletePhoneNumberRequest",
  {
    method: "delete",
    endpoint: userPhoneNumbers,
  }
);

export const registrationStartRequest = createRequest<PostPhoneNumberBody>(
  "registrationStartRequest",
  {
    method: "post",
    endpoint: registrationStart,
  }
);

export const registrationVerifyPhoneRequest =
  createRequest<PostVerifyPhoneNumberBody>("registrationVerifyPhoneRequest", {
    method: "post",
    endpoint: registrationVerifyPhone,
  });

export const registrationCreateUserRequest = createRequest<PostCreateUserBody>(
  "registrationCreateUserRequest",
  {
    method: "post",
    endpoint: registrationCreateUser,
  }
);

export const registrationVerifyEmailRequest =
  createRequest<PostVerifyEmailBody>("registrationVerifyEmailRequest", {
    method: "post",
    endpoint: registrationVerifyEmail,
  });

export const loginRequest = createRequest<PostLoginBody>("loginRequest", {
  method: "post",
  endpoint: login,
});

export const logoutRequest = createRequest("logoutRequest", {
  method: "post",
  endpoint: logout,
});

export const getCurrentUserRequest = createRequest("getCurrentUserRequest", {
  method: "post",
  endpoint: currentUser,
});

export const deleteCurrentUserRequest = createRequest(
  "deleteCurrentUserRequest",
  {
    method: "delete",
    endpoint: currentUser,
  }
);

export const uploadVerificationKitRequest =
  createRequest<PostUserVerificationKitBody>("uploadVerificationKitRequest", {
    method: "post",
    endpoint: userVerificationKit,
  });

export const forgotPasswordRequest = createRequest<PostForgotPasswordBody>(
  "forgotPasswordRequest",
  {
    method: "post",
    endpoint: userForgotPassword,
  }
);

export const passwordUpdateRequest = createRequest<PatchUserPasswordBody>(
  "passwordUpdateRequest",
  {
    method: "patch",
    endpoint: userUpdatePassword,
  }
);

export const postPaymentCheckoutSessionRequest =
  createRequest<PostPaymentCheckoutSessionBody>(
    "postPaymentCheckoutSessionRequest",
    {
      method: "post",
      endpoint: paymentCheckoutSession,
    }
  );
