import { TId } from "types";
import { NEXT_PUBLIC_API_ENDPOINT } from "envVars";

const BASE_URL = NEXT_PUBLIC_API_ENDPOINT;

type PostId = {
  postId: TId;
};
type MediaId = {
  mediaId: TId;
};

// USER
export const registrationStart = () => `${BASE_URL}/users/register/1-start`;
export const registrationVerifyPhone = () =>
  `${BASE_URL}/users/register/2-verify-phonenumber`;
export const registrationCreateUser = () =>
  `${BASE_URL}/users/register/3-create-user`;
export const registrationVerifyEmail = () =>
  `${BASE_URL}/users/register/4-verify-email`;
export const login = () => `${BASE_URL}/users/login`;
export const logout = () => `${BASE_URL}/users/logout`;
export const currentUser = () => `${BASE_URL}/users/me`;
export const userPhoneNumbers = () => `${BASE_URL}/users/phonenumbers`;
export const verifyUserPhoneNumber = () =>
  `${BASE_URL}/users/phonenumbers/verify`;
export const userVerificationKit = () => `${BASE_URL}/users/verification-kit`;
export const userForgotPassword = () => `${BASE_URL}/users/forgot-password`;
export const userUpdatePassword = () => `${BASE_URL}/users/update-password`;
export const userTransactions = () => `${BASE_URL}/users/transactions/me`;
export const adminTransferCredits = () =>
  `${BASE_URL}/admin/transactions/transfer`;

// MEDIA
export const media = () => `${BASE_URL}/media`;
export const mediaItem = ({ mediaId }: MediaId) =>
  `${BASE_URL}/media/${mediaId}`;

// POSTS
export const post = ({ postId }: PostId) => `${BASE_URL}/posts/${postId}`;
export const posts = () => `${BASE_URL}/posts`;
export const currentUserPosts = () => `${BASE_URL}/posts/me`;
export const userPosts = () => `${BASE_URL}/posts/user`;
export const localidad = () => `${BASE_URL}/posts/localidad`;
export const postViewCount = ({ postId }: PostId) =>
  `${BASE_URL}/posts/${postId}/view-count`;
export const postStatus = ({ postId }: PostId) =>
  `${BASE_URL}/posts/${postId}/status`;
export const postTop = ({ postId }: PostId) =>
  `${BASE_URL}/posts/${postId}/top`;
export const postFeatured = ({ postId }: PostId) =>
  `${BASE_URL}/posts/${postId}/featured`;

// ADMIN
export const adminLoginAs = () => `${BASE_URL}/admin/login-as`;
export const adminPosts = () => `${BASE_URL}/admin/posts`;
export const adminPost = ({ postId }: PostId) =>
  `${BASE_URL}/admin/posts/${postId}`;
export const adminMedia = ({ mediaId }: MediaId) =>
  `${BASE_URL}/admin/media/${mediaId}`;
export const adminMediaPending = () =>
  `${BASE_URL}/admin/media/pending-verification`;

// PAYMENTS
export const paymentCheckoutSession = () =>
  `${BASE_URL}/payment/checkout-sessions`;
