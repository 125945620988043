import { useCountry } from "common";
import { Category } from "types";
import { CATEGORIES } from "consts";

export const useAnunciosMetadata = ({
  categoryKey,
  location,
}: {
  categoryKey: Category;
  location: string;
}) => {
  const country = useCountry();
  const category = CATEGORIES[categoryKey];

  switch (categoryKey) {
    case "escorts": {
      const label = country.code === "CO" ? "Prepagos" : "Putas";
      return {
        title: (
          <title>
            &#x1F48B; {category} y {label} en {location} - LOCALEROTICA
          </title>
        ),
        description: (
          <meta
            key="desc"
            name="description"
            content={`${category} y ${label} en ${location}. Cumple todas tus fantasías. Te están esperando. Publica gratis.`}
          />
        ),
      };
    }
    case "escorts-gay": {
      return {
        title: (
          <title>
            &#x1F49A; {category} en {location} - LOCALEROTICA
          </title>
        ),
        description: (
          <meta
            key="desc"
            name="description"
            content={`${category} en ${location}. Cumple todas tus fantasías. Te están esperando. Publica gratis.`}
          />
        ),
      };
    }
    case "masajes-eroticos": {
      return {
        title: (
          <title>
            &#x1F49B; {category} en {location} - LOCALEROTICA
          </title>
        ),
        description: (
          <meta
            key="desc"
            name="description"
            content={`${category} en ${location}. Cumple todas tus fantasías. Te están esperando. Publica gratis.`}
          />
        ),
      };
    }
    case "gigolos": {
      return {
        title: (
          <title>
            &#x1F499; {category} en {location} - LOCALEROTICA
          </title>
        ),
        description: (
          <meta
            key="desc"
            name="description"
            content={`${category} en ${location}. Cumple todas tus fantasías. Te están esperando. Publica gratis.`}
          />
        ),
      };
    }
    case "travestis": {
      return {
        title: (
          <title>
            &#x1F49C; {category} en {location} - LOCALEROTICA
          </title>
        ),
        description: (
          <meta
            key="desc"
            name="description"
            content={`${category} en ${location}. Cumple todas tus fantasías. Te están esperando. Publica gratis.`}
          />
        ),
      };
    }
    case "servicios-virtuales": {
      return {
        title: (
          <title>
            &#x1F9E1; {category} en {location} - LOCALEROTICA
          </title>
        ),
        description: (
          <meta
            key="desc"
            name="description"
            content={`${category} en ${location}. Cumple todas tus fantasías. Te están esperando. Publica gratis.`}
          />
        ),
      };
    }
    default: {
      return {
        title: <title>&#x1F451; LOCALEROTICA: Anuncios eróticos</title>,
        description: (
          <meta
            key="desc"
            name="description"
            content="Busca anuncios eróticos y publica gratis."
          />
        ),
      };
    }
  }
};
