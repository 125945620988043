// lodash
import isPlainObject from "lodash/isPlainObject";
import throttle from "lodash/throttle";

import axios, { AxiosRequestConfig } from "axios";
import { race, take, put, all, takeEvery, call } from "redux-saga/effects";
import { endRequestAction, successActionType, errorActionType } from "utils";
import { RequestAction } from "types";
import { uploadMediaProgress, assignNormalizedValues } from "actions";
import {
  deleteCurrentUserRequest,
  uploadMediaRequestActionType,
} from "requests";
import { NEXT_PUBLIC_COMMIT_HASH } from "envVars";
import { store } from "store/configure-store";

// const SUBMIT_ACTIONS_TIMEOUT = 1000 * 10;

function* requestSaga(action: RequestAction) {
  const { payload, meta, type } = action;
  // If there's no meta.api, the action is not a request
  if (!meta || !meta.api) return;

  // Support for onResponse.success and error for submit requests
  if (meta.api.method === "submit") {
    const { submitSuccess, submitError } = yield race({
      submitSuccess: take(successActionType(action.type)),
      submitError: take(errorActionType(action.type)),
      // timeout: delay(SUBMIT_ACTIONS_TIMEOUT),
    });

    if (submitSuccess) {
      const onSuccessCallback = meta.onResponse?.successCallback;
      if (onSuccessCallback) yield call(onSuccessCallback, submitSuccess);
    }

    if (submitError) {
      const onErrorCallback = meta.onResponse?.errorCallback;
      if (onErrorCallback) yield call(onErrorCallback, submitError);
    }

    return;
  }

  const { api, context, onResponse } = meta;

  const headers: { [key: string]: string } = {
    "X-App-Version": NEXT_PUBLIC_COMMIT_HASH ?? "dev",
    "Content-Type": "application/json",
  };

  const isUploadFileRequest = type === uploadMediaRequestActionType;
  const isDeleteUserRequest = type === deleteCurrentUserRequest.type;

  const onUploadProgress = ({ loaded, total }: any) => {
    store.dispatch(
      uploadMediaProgress({
        loaded,
        total,
        url: context ?? "",
        contentType: (payload as FormData).get("Content-Type") as string,
      })
    );
  };

  const thottledOnUploadProgress = throttle(onUploadProgress, 1000, {
    leading: true,
    trailing: true,
  });

  const config: AxiosRequestConfig = {
    withCredentials: !isUploadFileRequest,
    headers: { ...headers, ...api.overrideHeaders },
    method: api.method,
    timeout: 1000 * (isUploadFileRequest || isDeleteUserRequest ? 0 : 10), // 0 = no timeout
    url: api.endpoint(payload),
    onUploadProgress: isUploadFileRequest
      ? thottledOnUploadProgress
      : undefined,
  };

  if (api.method === "get") {
    config.params = payload;
  } else {
    config.data = payload;
  }

  try {
    const { data, status } = yield call(axios.request, config);

    if (isPlainObject(data)) {
      yield put(assignNormalizedValues({ OGActionType: action.type, data }));
    }

    yield put(
      endRequestAction({
        request: action,
        requestStep: "success",
        params: {
          payload: {
            data: api.transformResponse
              ? api.transformResponse(data, payload)
              : data,
            status,
          },
          meta: {
            context,
          },
        },
      })
    );

    const onSuccessCallback = onResponse?.successCallback;
    if (onSuccessCallback) yield call(onSuccessCallback, data);
  } catch (error: any) {
    const data = error?.response?.data;
    const status = error?.response?.status;

    yield put(
      endRequestAction({
        request: action,
        requestStep: "error",
        params: {
          payload: {
            data,
            status,
          },
          meta: {
            context,
          },
        },
      })
    );

    const onErrorCallback = onResponse?.errorCallback;
    if (onErrorCallback) yield call(onErrorCallback, data);
  }
}

export function* apiSagas() {
  yield all([takeEvery("*", requestSaga)]);
}
