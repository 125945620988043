// lodash
import first from "lodash/first";
import map from "lodash/map";

import { useCountry, FormInput } from "common";
import { CATEGORIES } from "consts";

export const formKeys = {
  category: "category",
  estado: "estado",
  localidad: "localidad",
};

export const CATEGORIES_OPTIONS = map(CATEGORIES, (category, key) => ({
  label: category,
  value: key,
}));

export const useFormInputs = (): Array<FormInput> => {
  const country = useCountry();

  const category = {
    key: formKeys.category,
    value: first(CATEGORIES_OPTIONS)!.value,
    label: "Categoría",
    required: true,
  };

  const estado = {
    key: formKeys.estado,
    value: "",
    label: country.estado,
  };

  const localidad = {
    key: formKeys.localidad,
    value: "",
    label: "Localidad",
  };

  return [category, estado, localidad];
};
