// Material Components
import LoadingButton from "@mui/lab/LoadingButton";

import { ReactNode } from "react";

export const Button = ({
  disabled,
  loading,
  children,
  onClick,
  color,
  className,
}: {
  disabled?: boolean;
  loading?: boolean;
  children: ReactNode;
  onClick?: () => void;
  color?: "primary" | "secondary" | "error";
  className?: string;
}) => (
  <LoadingButton
    variant="contained"
    className={className}
    color={color}
    onClick={onClick}
    disabled={disabled}
    loading={loading}
  >
    <span>{children}</span>
  </LoadingButton>
);
