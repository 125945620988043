import { createTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

// Create a theme instance.
export const theme = createTheme({
  palette: {
    mode: "dark",
    contrastThreshold: 4.5,
    primary: {
      main: "#ff2ea1",
    },
    secondary: {
      main: grey[300],
    },
    blue: {
      main: "#6bfaff",
      light: "#a5ffff",
      dark: "#21c7cc",
    },
    highlight: {
      main: "#ffff00",
    },
    background: {
      default: "#141414",
      light: "#292929",
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "filled",
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            background: "rgba(255, 255, 255, 0.03)",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        asterisk: {
          color: "#ff2ea1",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#ff2ea1",
        },
      },
    },
  },
});
