import { COUNTRY_DATA } from "consts";
import { NEXT_PUBLIC_COUNTRY_CODE } from "envVars";
import { Country } from "types";

const countryCode = NEXT_PUBLIC_COUNTRY_CODE as Country;

export const useCountry = () => {
  // Default to México
  return COUNTRY_DATA[countryCode];
};
