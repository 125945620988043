import { createAction } from "@reduxjs/toolkit";

export const initializeApp = createAction("initializeApp");

export const uploadMediaProgress = createAction<{
  loaded: number;
  total: number;
  url: string;
  contentType: string;
}>("uploadMediaProgress");

export const resetStore = createAction("resetStore");
