// Material Components
import UserFoundIcon from "@mui/icons-material/Person";
import ListIcon from "@mui/icons-material/List";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TwitterIcon from "@mui/icons-material/Twitter";

import { FC, ComponentClass } from "react";
import dynamic from "next/dynamic";

export const APROBAR_ANUNCIOS = "aprobar-anuncios";

// We need to do this to persist the layout + drawer
export const viewMap: {
  [view: string]: { Component: FC | ComponentClass; title: string; Icon: FC };
} = {
  [APROBAR_ANUNCIOS]: {
    Component: dynamic(() =>
      import("./AprobarAnuncios").then((mod) => mod.AprobarAnuncios)
    ),
    title: "Aprobar Anuncios",
    Icon: ListIcon,
  },
  "aprobar-anuncio": {
    Component: dynamic(() =>
      import("./AprobarAnuncios/AprobarAnuncio").then(
        (mod) => mod.AprobarAnuncio
      )
    ),
    title: "",
    Icon: () => null,
  },
  verificacion: {
    Component: dynamic(() =>
      import("./Verificacion").then((mod) => mod.Verificacion)
    ),
    title: "Verificación",
    Icon: CheckCircleIcon,
  },
  "verificar-usuario": {
    Component: dynamic(() =>
      import("./Verificacion/VerificarUsuario").then(
        (mod) => mod.VerificarUsuario
      )
    ),
    title: "",
    Icon: () => null,
  },
  creditos: {
    Component: dynamic(() =>
      import("./TransferirCredito").then((mod) => mod.TransferirCredito)
    ),
    title: "Transferir Crédito",
    Icon: AttachMoneyIcon,
  },
  "login-as": {
    Component: dynamic(() => import("./LoginAs").then((mod) => mod.LoginAs)),
    title: "Login As",
    Icon: UserFoundIcon,
  },
  twitter: {
    Component: dynamic(() => import("./Twitter").then((mod) => mod.Twitter)),
    title: "Twitter",
    Icon: TwitterIcon,
  },
  "twitter-ciudad": {
    Component: dynamic(() =>
      import("./Twitter/TwitterCiudad").then((mod) => mod.TwitterCiudad)
    ),
    title: "",
    Icon: () => null,
  },
  "cerrar-sesion": {
    Component: dynamic(() =>
      import("./CerrarSesion").then((mod) => mod.CerrarSesion)
    ),
    title: "Cerrar Sesión",
    Icon: ExitToAppIcon,
  },
};
