// lodash
import concat from "lodash/concat";
import isEmpty from "lodash/isEmpty";
import compact from "lodash/compact";
import map from "lodash/map";

import { put, all, call, takeLeading } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { uploadVerificationKit } from "actions";
import { uploadVerificationKitRequest } from "requests";
import { uploadMediaSaga, UploadMediaSagaReturnType } from "../media/sagas";
import { awaitResponse, endRequestAction } from "utils";
import { NewFile } from "types";
import { deleteCreatedMedia } from "store/posts/sagas";

function* uploadVerificationKitSaga({
  payload: { newFiles },
}: PayloadAction<{ newFiles: Array<NewFile> }>) {
  const {
    responses,
    uploadedVideoIds,
    uploadedImageIds,
  }: UploadMediaSagaReturnType = yield call(uploadMediaSaga, newFiles);

  const errors = compact(map(responses, "error"));
  const uploadedMediaIds = concat(uploadedImageIds, uploadedVideoIds);

  if (!isEmpty(errors)) {
    yield put(
      endRequestAction({
        request: uploadVerificationKit,
        requestStep: "error",
        params: { payload: { errors, devMessage: "errors uploading media" } },
      })
    );

    yield call(deleteCreatedMedia, uploadedMediaIds);
    return;
  }

  const { error: uploadVerificationKitError } = yield call(
    awaitResponse,
    uploadVerificationKitRequest({ mediaIds: uploadedMediaIds })
  );

  if (uploadVerificationKitError) {
    yield put(
      endRequestAction({
        request: uploadVerificationKit,
        requestStep: "error",
        params: { payload: { error: uploadVerificationKitError } },
      })
    );

    yield call(deleteCreatedMedia, uploadedMediaIds);
    return;
  }

  yield put(
    endRequestAction({
      request: uploadVerificationKit,
      requestStep: "success",
      params: { payload: {} },
    })
  );
}

export function* usersSagas() {
  yield all([takeLeading(uploadVerificationKit, uploadVerificationKitSaga)]);
}
