import { KeyboardEvent } from "react";
import { Action, createAction } from "@reduxjs/toolkit";
import { take, race, put } from "redux-saga/effects";
import {
  ResponseAction,
  RequestApiData,
  RequestMeta,
  RequestData,
  RequestAction,
  PostPackage,
} from "types";

export * from "../../shared/utils";

export const localStorageSetItem = ({
  key,
  value,
}: {
  key: string;
  value: string;
}) => {
  // We need to perform this check, because localstorage is not
  // available to Next server-side
  if (typeof window === "undefined") return;
  localStorage.setItem(key, value);
};

export const localStorageGetItem = ({ key }: { key: string }) => {
  // We need to perform this check, because localstorage is not
  // available to Next server-side
  if (typeof window === "undefined") return;
  return localStorage.getItem(key);
};

export const localStorageRemoveItem = ({ key }: { key: string }) => {
  // We need to perform this check, because localstorage is not
  // available to Next server-side
  if (typeof window === "undefined") return;
  localStorage.removeItem(key);
};

export const successActionType = (type: string) => `${type}_Success`;
export const errorActionType = (type: string) => `${type}_Error`;

export const buildPostPackageEndDate = (postPackage: PostPackage) => {
  const { startingDate, durationDays } = postPackage;
  const date = new Date(startingDate);
  date.setDate(date.getDate() + durationDays);
  return date.toLocaleDateString("es-MX", {
    weekday: "long",
    day: "numeric",
    month: "long",
  });
};

export const endRequestAction = ({
  request,
  requestStep,
  params,
}: {
  request: Action;
  requestStep: "success" | "error";
  params?: { payload?: any; meta?: { context?: string } };
}): ResponseAction => ({
  type:
    requestStep === "success"
      ? successActionType(request.type)
      : errorActionType(request.type),
  meta: {
    requestStep: requestStep as any,
    OGActionType: request.type,
    ...params?.meta,
  },
  payload: params?.payload,
});

// const patchChannelRequest = createRequest<PayloadType>("patchChannelRequest", {
//   method: "get",
//   endpoint: (payload) => patchChannelEndpoint(payload),
//   transformResponse: (responseData, payload) => ({
//     channels: responseData,
//     patientId: payload.patientId,
//   }),
// });
export const createRequest = <P = void>(type: string, api: RequestApiData<P>) =>
  createAction(
    type,
    (payload: P, meta?: RequestMeta): RequestData<P> => ({
      payload,
      meta: {
        ...meta,
        api,
      },
    })
  );

// <input onKeyDown={onEnter(() => console.log("enter pressed"))} />
export const onEnter =
  (callback: () => any) => (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      callback();
    }
  };

// const { success, error } = yield call(awaitResponse, patchChannelRequest(payload))
export function* awaitResponse(action: RequestAction) {
  yield put(action);

  const isSameRequest = (
    takeAction: Action,
    helper: (actionType: string) => string
  ): boolean => {
    const isSameAction = helper(action.type) === takeAction.type;
    const isSameContext =
      (takeAction as RequestAction).meta?.context === action.meta?.context;
    return isSameAction && isSameContext;
  };

  // This would return the response of the first
  // action with that type to appear. I think this is not
  // a problem, but we could add actionId if we need it
  const response: {
    success: ResponseAction;
    error: ResponseAction;
  } = yield race({
    success: take((takeAction: Action) =>
      isSameRequest(takeAction, successActionType)
    ),
    error: take((takeAction: Action) =>
      isSameRequest(takeAction, errorActionType)
    ),
  });

  return response;
}

export const whatsappHrefBuilder = ({
  message,
  phoneNumber,
  phoneCountryCode,
}: {
  message: string;
  phoneNumber: string;
  phoneCountryCode: string;
}) => {
  return `https://wa.me/${phoneCountryCode}${phoneNumber}/?text=${encodeURI(
    message
  )}`;
};
