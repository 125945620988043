// Material Components
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
// Material Icons
import CloseIcon from "@mui/icons-material/Close";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { closeSnackbar } from "actions";
import { selectOpenSnackbar } from "selectors";
import { useSelector } from "common";

export const SnackbarManager = () => {
  const dispatch = useDispatch();
  // Material design guidelines say that snackbars should be sequential instead
  // of parallel, but we could revisit this if we needed parallel snackbars
  const openSnackbar = useSelector(selectOpenSnackbar);

  // We keep track of the snackbar's open status here to allow the exit animation to play
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (openSnackbar) setOpen(true);
  }, [openSnackbar?.key]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!openSnackbar) return null;
  const { variant, message, options, key } = openSnackbar;

  // onClose is called whenever the snackbar automatically closes
  const onClose = (_: any, reason: SnackbarCloseReason) => {
    if (reason === "clickaway") return;
    setOpen(false);
  };

  // onExited is called whenever the snackbar has finished animating out,
  // so we can remove it without a jarring transition
  const onExited = () => {
    dispatch(closeSnackbar({ key }));
  };

  return (
    <Snackbar
      onClose={onClose}
      TransitionProps={{
        onExited,
      }}
      open={open}
      key={key}
      autoHideDuration={1000 * 5}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      {...options}
    >
      <Alert
        variant="filled"
        severity={variant}
        action={[
          <IconButton
            size="small"
            key="close"
            aria-label="close"
            color="inherit"
            onClick={() => setOpen(false)}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};
