import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import {
  NEXT_PUBLIC_S3_ENDPOINT,
  NEXT_PUBLIC_API_ENDPOINT,
  NEXT_PUBLIC_S3_BUCKET,
  NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN,
  IS_DEV,
} from "envVars";

// import LogRocket from "logrocket";

// if (!IS_DEV) {
//   LogRocket.init("eegkxx/localerotica");
// }

import mixpanel from "mixpanel-browser";
mixpanel.init(NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN!, {
  debug: IS_DEV,
  secure_cookie: !IS_DEV,
});

import Head from "next/head";
import { useEffect } from "react";
import { AppProps } from "next/app";
import styled from "@emotion/styled";
import {
  ModalManager,
  SnackbarManager,
  LegalModal,
  LEGAL_STORAGE_KEY,
  useDispatch,
  TopBar,
  Footer,
} from "common";
import { ThemeProvider } from "@mui/material/styles";
import { openModal, initializeApp } from "actions";
import CssBaseline from "@mui/material/CssBaseline";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-next-router";
import { theme } from "theme";
import { store } from "store/configure-store";

const DispatchInitializeApp = () => {
  // We dispatch initializeApp once components have been mounted
  const dispatch = useDispatch();

  useEffect(() => {
    window.name = "localerotica";
    // We do this so the modal doesn't show when google crawls
    // the site, because it lowers our performance scores
    dispatch(initializeApp());
    window.addEventListener("mousemove", mouseMovement, false);
    window.addEventListener("touchstart", touchActivity, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openLegalModal = () => {
    const hasAccepted = window.localStorage.getItem(LEGAL_STORAGE_KEY);

    if (!hasAccepted) {
      dispatch(openModal({ node: <LegalModal />, disableEscClose: true }));
      return;
    }

    const acceptedAt = new Date(hasAccepted!);
    let twoHoursAgo = new Date();
    twoHoursAgo.setHours(twoHoursAgo.getHours() - 1);

    if (acceptedAt < twoHoursAgo) {
      dispatch(openModal({ node: <LegalModal /> }));
    }
  };

  const mouseMovement = () => {
    // Kill the event listener, so it executes only once
    window.removeEventListener("mousemove", mouseMovement, false);
    window.removeEventListener("touchstart", touchActivity, false);
    openLegalModal();
  };

  const touchActivity = () => {
    // Kill the event listener, so it executes only once
    window.removeEventListener("mousemove", mouseMovement, false);
    window.removeEventListener("touchstart", touchActivity, false);
    openLegalModal();
  };

  return null;
};

const CSP_default = `default-src 'self';`;
const CSP_child = `child-src 'self' blob:;`;
const CSP_worker = `worker-src 'self' blob:;`;
const CSP_style = `style-src 'self' 'unsafe-inline';`;
const CSP_script = `script-src 'self' 'unsafe-eval' https://cdn.logrocket.io https://cdn.lr-ingest.io https://cdn.lr-in.com https://cdn.lr-in-prod.com/logger-1.min.js;`;
const CSP_object = `object-src 'none';`;
const CSP_media = `media-src blob: ${NEXT_PUBLIC_S3_ENDPOINT};`;
const CSP_img = `img-src 'self' blob: data: ${NEXT_PUBLIC_S3_ENDPOINT};`;
const CSP_connect = `connect-src 'self' ${NEXT_PUBLIC_API_ENDPOINT} ${NEXT_PUBLIC_S3_ENDPOINT} https://s3.amazonaws.com/${NEXT_PUBLIC_S3_BUCKET} https://api-js.mixpanel.com https://*.logrocket.io https://*.lr-ingest.io https://*.logrocket.com https://*.lr-in.com;`;

export default function MyApp(props: AppProps) {
  const { Component, pageProps } = props;
  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <title>&#x1F525; LOCALEROTICA</title>

        <link rel="dns-prefetch" href={NEXT_PUBLIC_API_ENDPOINT} />
        <link rel="preconnect" href={NEXT_PUBLIC_API_ENDPOINT} />

        <link rel="dns-prefetch" href={NEXT_PUBLIC_S3_ENDPOINT} />
        <link rel="preconnect" href={NEXT_PUBLIC_S3_ENDPOINT} />

        <meta
          key="desc"
          name="description"
          content="Busca anuncios eróticos reales y publica gratis."
        />
        <meta
          key="CSP"
          httpEquiv="Content-Security-Policy"
          content={
            CSP_default +
            CSP_style +
            CSP_script +
            CSP_object +
            CSP_img +
            CSP_media +
            CSP_connect +
            CSP_child +
            CSP_worker
          }
        />

        <meta
          key="og:image"
          property="og:image"
          content="https://s3.localerotica.com/static/full-logo.jpg"
        />
      </Head>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Provider store={store}>
          <ConnectedRouter>
            <DispatchInitializeApp />
            <SnackbarManager />
            <ModalManager />
            <Container>
              <TopBar />
              <Component {...pageProps} />
              <Footer />
            </Container>
          </ConnectedRouter>
        </Provider>
      </ThemeProvider>
    </>
  );
}

const Container = styled.div`
  height: 100vh;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  * {
    box-sizing: border-box;
  }
`;
