import { createRequest } from "utils";
import { userTransactions } from "store/api/endpoints";

export const getUserTransactionsRequest = createRequest(
  "getUserTransactionsRequest",
  {
    method: "get",
    endpoint: userTransactions,
  }
);
