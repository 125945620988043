import { createAction } from "@reduxjs/toolkit";
import { createRequest } from "utils";
import { NewFile } from "types";

export const setCurrentUserId = createAction<string | undefined>(
  "setCurrentUserId"
);
export const uploadVerificationKit = createRequest<{
  newFiles: Array<NewFile>;
}>("uploadVerificationKit", {
  method: "submit",
});
