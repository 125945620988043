// Material
import LinearProgress from "@mui/material/LinearProgress";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";

// lodash
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import startsWith from "lodash/startsWith";

import { Fragment } from "react";
import styled from "@emotion/styled";
import { PlayIconContainer, Loading, useSelector } from "common";
import { selectUploadMediaProgress } from "selectors";
import { theme } from "theme";

export const UploadMediaProgress = () => {
  const uploadMediaProgress = useSelector(selectUploadMediaProgress);

  const renderProgress = (
    progress: { loaded: number; total: number; contentType: string },
    url: string
  ) => {
    if (!uploadMediaProgress) return null;
    const percentage = Math.round((progress.loaded / progress.total) * 100);

    return (
      <Fragment key={url}>
        {startsWith(progress.contentType, "video") ? (
          <VideoContainer>
            <video preload="metadata">
              <source src={url + "#t=0.1"} type={progress.contentType} />
            </video>
            <PlayIconContainer>
              <PlayCircleFilledIcon />
            </PlayIconContainer>
          </VideoContainer>
        ) : (
          <img src={url} onError={() => console.log("error")} />
        )}

        <ProgressContainer>
          <LinearProgress variant="determinate" value={percentage} />
          <h2>{percentage}%</h2>
        </ProgressContainer>
      </Fragment>
    );
  };

  return (
    <FixedContainer>
      <LoadingContainer>
        <h2>
          Subiendo fotos/videos
          <br />
          Puede tardar un minuto...
        </h2>
        {isEmpty(uploadMediaProgress) && <Loading size={50} />}
        <ProgressGroup>
          {map(uploadMediaProgress, renderProgress)}
        </ProgressGroup>
      </LoadingContainer>
    </FixedContainer>
  );
};

const FixedContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  overflow-x: hidden;
  background: ${theme.palette.background.default};
  z-index: 5;
`;
const VideoContainer = styled.div`
  position: relative;
`;
const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;

  img {
    margin-top: 20px;
    height: 300px;
    width: 300px;
    max-width: 80%;
    object-fit: contain;
  }

  video {
    width: 300px;
    max-width: 80%;
  }
`;
const ProgressGroup = styled.div``;
const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .MuiLinearProgress-root {
    width: 400px;
    max-width: 60%;
    margin-right: 20px;
  }
`;
