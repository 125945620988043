// Material Icons
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import styled from "@emotion/styled";
import { theme } from "theme";

export const AddMediaButton = ({ onClick }: { onClick: () => void }) => (
  <Button onClick={onClick}>
    <Icon>
      <PhotoCameraIcon fontSize="inherit" />
      <AddCircleIconContainer>
        <AddCircleIcon fontSize="large" />
      </AddCircleIconContainer>
    </Icon>
    <p>Agregar fotos / videos</p>
  </Button>
);

const Button = styled.button`
  margin-top: 10px;
  color: white;
  font-size: 100px;
  border-radius: 5px;
  padding: 10px;
  background: ${theme.palette.background.light};
  border: none;
  border-bottom: 2px solid transparent;
  cursor: pointer;

  p {
    font-size: 1rem;
    margin: 0;
    margin-top: -20px;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.15);
    border-bottom: 2px solid ${theme.palette.primary.main};
  }
`;
const Icon = styled.div`
  position: relative;
  width: 100px;
  margin: 0 auto;
`;
const AddCircleIconContainer = styled.div`
  color: ${theme.palette.primary.main};
  display: flex;
  position: absolute;
  right: 0;
  bottom: 30px;
`;
