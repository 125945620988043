export const IMAGES_SIZES = {
  thumbnail: {
    resizeMode: "cover",
    height: 180,
    width: 120,
  },
  small: {
    resizeMode: "outside",
    height: 300,
    width: 300,
  },
  large: {
    resizeMode: "inside",
    height: 750,
    width: 750,
  },
} as const;

export const PREPROCESSED_IMAGES = "preprocessed-image/";
export const PREPROCESSED_VIDEOS = "preprocessed-video/";

export const USER_MEDIA = "user-media/";
