// lodash
import filter from "lodash/filter";
import compact from "lodash/compact";
import map from "lodash/map";

import { RootState, TId } from "types";

export const selectMedia = (state: RootState, mediaId: TId) =>
  state.media.byId[mediaId];

export const selectMediaByIds = (state: RootState, mediaIds: Array<TId>) =>
  compact(map(mediaIds, (mediaId) => state.media.byId[mediaId]));

export const selectUserMedia = (state: RootState, userId: TId) =>
  filter(state.media.byId, { userId });

export const selectAllMediaById = (state: RootState) => state.media.byId;
