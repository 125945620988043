// lodash
import map from "lodash/map";

import { buildQueryString } from "common";
import { TPost, GetPostsQuery, RootState, TId } from "types";

export const selectPost = (state: RootState, postId?: TId): TPost | undefined =>
  postId ? state.posts.byId[postId] : undefined;

export const selectAllPostsById = (state: RootState) => state.posts.byId;

export const selectMediaIdsByPostId = (state: RootState, postId: TId) =>
  state.posts.mediaIdsByPostId[postId];

export const selectAllMediaIdsByPostId = (state: RootState) =>
  state.posts.mediaIdsByPostId;

export const selectPostsByIds = (state: RootState, postIds: Array<TId>) =>
  map(postIds, (postId) => state.posts.byId[postId]);

export const selectCurrentUserPosts = (state: RootState) =>
  state.posts.userPostsById;

export const selectPostsFetchedAt = (state: RootState, query: GetPostsQuery) =>
  state.posts.fetchedAt[buildQueryString(query)];

export const selectCurrentQueryResults = (state: RootState) =>
  state.posts.currentQueryResults;

export const selectFavouritePostIds = (state: RootState) =>
  state.posts.favouritePostIds;

export const selectPostBackLink = (state: RootState) => state.posts.backLink;
