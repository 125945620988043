import { TId, RootState } from "types";

export const selectAdminAllPostsToApproveIds = (state: RootState) =>
  state.admin.postsToApproveIds;

export const selectAdminVerificationMediaIdsByUserId = (
  state: RootState,
  userId: TId
) => state.admin.verificationMediaIdsByUserId[userId];

export const selectAdminAllVerificationMediaIdsByUserId = (state: RootState) =>
  state.admin.verificationMediaIdsByUserId;
