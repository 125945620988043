import styled from "@emotion/styled";
import { SVG_HEIGHT, RawCardBrandsSvg } from "./raw-svg-card-brands";

export const MastercardSvg = () => (
  <Container>
    <Mastercard />
  </Container>
);

const Container = styled.div`
  position: relative;
  height: ${SVG_HEIGHT};
  overflow: hidden;
  width: 136px;
`;
const Mastercard = styled(RawCardBrandsSvg)`
  position: absolute;
  height: 100%;
  width: auto;
  left: -76px;
`;
