import { useDispatch, Modal } from "common";
import { adminDeleteMediaRequest } from "requests";
import { closeModal } from "actions";
import { TId } from "types";

export const ConfirmDeleteModal = ({ mediaId }: { mediaId: TId }) => {
  const dispatch = useDispatch();

  const deleteMedia = () => {
    dispatch(
      adminDeleteMediaRequest({ mediaId: mediaId }, { context: mediaId })
    );
    dispatch(closeModal());
  };

  return (
    <Modal
      title="Borrar media"
      variant="warning"
      submitButton={{ text: "Borrar", onClick: deleteMedia }}
    >
      ¿Estás seguro?
    </Modal>
  );
};
