// lodash
import find from "lodash/find";
// Material Components
import MuiAutocomplete, {
  createFilterOptions,
} from "@mui/material/Autocomplete";

import { useEffect, useState, useRef } from "react";
import { useIsMobile, STextField, Form } from "common";

const ADD_NEW_VALUE = "add-new-value";

export const Autocomplete = ({
  formKey,
  form,
  options,
  noOptionsText,
  enableAddNew,
}: {
  formKey: string;
  form: Form;
  options: Array<{ value: any; label: string }>;
  noOptionsText?: string;
  enableAddNew?: {
    onAdd: (input: string) => void;
  };
}) => {
  const [input, setInput] = useState("");
  const { onChange, formValues } = form;
  const isMobile = useIsMobile();
  const [size, setSize] = useState<"small" | "medium">("small");
  const filter = useRef(
    createFilterOptions<{ label: string; value: string }>()
  );

  const thisFormValues = formValues[formKey];

  useEffect(() => {
    setSize(isMobile ? "small" : "medium");
  }, [isMobile]);

  return (
    <MuiAutocomplete
      fullWidth
      size={size}
      noOptionsText={noOptionsText}
      value={find(options, { value: thisFormValues.value }) || null}
      onChange={(_: any, newValue: { value: string; label: string } | null) => {
        if (newValue?.value === ADD_NEW_VALUE) {
          // If the input belongs to the selected option, the user
          // obviously doesn't want to add that a new localidad
          // since it already exists. So we call onAdd with no input
          const inputBelongsToSelectedOption = Boolean(
            find(options, { label: input })
          );

          enableAddNew?.onAdd(inputBelongsToSelectedOption ? "" : input);
          return;
        }
        // We do this so the onChange method matches with that
        // of TextInput. It makes it easier to use both components
        // with useForm
        onChange(formKey)(newValue?.value);
      }}
      getOptionLabel={(option) => option.label}
      onInputChange={(_: any, value: string) => setInput(value)}
      filterOptions={
        !enableAddNew
          ? undefined
          : (options, params) => {
              const filtered = filter.current(options, params);

              const inputBelongsToSelectedOption = Boolean(
                find(options, { label: input })
              );

              const label =
                input === "" || inputBelongsToSelectedOption
                  ? "Agregar localidad"
                  : `Agregar: ${input}`;

              filtered.push({
                value: ADD_NEW_VALUE,
                label,
              });

              return filtered;
            }
      }
      options={options ?? []}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            {option.value === ADD_NEW_VALUE ? (
              <strong>{option.label}</strong>
            ) : (
              option.label
            )}
          </li>
        );
      }}
      disabled={thisFormValues.disabled}
      placeholder={thisFormValues.placeholder}
      renderInput={(params) => {
        return (
          <STextField
            required={thisFormValues.required}
            label={thisFormValues.label}
            error={Boolean(thisFormValues.error)}
            helperText={thisFormValues.error}
            {...params}
          />
        );
      }}
    />
  );
};
