import {
  TypedUseSelectorHook,
  useDispatch as OGuseDispatch,
  useSelector as OGuseSelector,
} from "react-redux";
import { RootState, AppDispatch } from "store/configure-store";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useDispatch = () => OGuseDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = OGuseSelector;
