// lodash
import transform from "lodash/transform";
import isString from "lodash/isString";
import trim from "lodash/trim";
import some from "lodash/some";
import merge from "lodash/merge";
import map from "lodash/map";
import forEach from "lodash/forEach";
import set from "lodash/set";
import unset from "lodash/unset";
import reduce from "lodash/reduce";

import { ReactNode, useState } from "react";

export type FormInput = {
  key: string;
  value?: any;
  type?: string;
  label?: string | ReactNode;
  noWhiteSpace?: boolean;
  forceLowerCase?: boolean;
  required?: boolean;
  disabled?: boolean;
  error?: string | undefined;
  placeholder?: string;
};

export type FormValues = {
  [key: string]: FormInput;
};

export type Form = {
  formValues: FormValues; // raw data
  setFormValues: (values: FormValues) => void; // raw power
  updateValues: (values: Array<FormInput>) => void;
  onChange: (key: string) => (value: any) => void;
  isDirty: boolean;
  setIsDirty: (isDirty: boolean) => void;
  getValuesObj: () => { [key: string]: any };
  getIsComplete: () => boolean;
  getIsValid: () => boolean;
};

export const REQUIRED_FIELD_ERROR = "El campo no puede estar vacío";

const buildInitial = (formInputs: Array<FormInput>) =>
  reduce(
    formInputs,
    (result: FormValues, formInput: FormInput) =>
      set(result, [formInput.key], formInput),
    {}
  );

export const useForm = (formInputs: Array<FormInput>): Form => {
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<FormValues>(
    buildInitial(formInputs)
  );

  /* creates new inputs and updates existing ones
   * only specified values will overwrite old values
   * all other values remain the same
   * updateValues([
   * { key: 'person/lastName', required: true, value: 'Mauldino' },
   * { key: 'channels/13243/identifier', error: null },
   * ])
   */
  const updateValues = (newValues: Array<FormInput>) => {
    const updatedValues: FormValues = {};
    forEach(newValues, (input) => {
      const { key } = input;
      forEach(input, (val, property) => {
        set(updatedValues, [key, property], val);
      });
    });
    const mergedValues = merge({}, formValues, updatedValues);
    setFormValues(mergedValues);
  };

  // Updates value, resets error, isDirty = true
  // <TextInput onChange={onChange('person/firstName')} />
  // <CustomDropdown onChange={onChange('person/gender')} />
  const onChange = (key: string) => (value: any) => {
    const cleanValue = formValues[key].noWhiteSpace
      ? value.replace(/\s/g, "")
      : value;
    const lowerCaseValue = formValues[key].forceLowerCase
      ? String(cleanValue).toLowerCase()
      : cleanValue;
    const mergedValues = merge({}, formValues, {
      [key]: { value: lowerCaseValue ?? "" },
    });
    unset(mergedValues, [key, "error"]);
    setFormValues(mergedValues);
    setIsDirty(true);
  };

  // Gets all values
  const getValuesObj = () =>
    transform(
      formValues,
      (tempObj, obj, key) => {
        set(tempObj, key, obj?.value);
      },
      {}
    );

  // true if no required fields are falsey
  const getIsComplete = () =>
    !some(
      map(formValues, ({ required, value }) => {
        const isEmpty = isString(value) ? !trim(value) : !value;
        return required && isEmpty;
      })
    );

  // false if any fields' error is truthy
  const getIsValid = () => !some(map(formValues, "error"));

  return {
    onChange,
    setFormValues,
    formValues,
    getValuesObj,
    getIsComplete,
    getIsValid,
    updateValues,
    isDirty,
    setIsDirty,
  };
};
