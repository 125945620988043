import { useState } from "react";
import styled from "@emotion/styled";
import {
  TextField,
  useForm,
  useDispatch,
  useSelector,
  Modal,
  FormInput,
  useCountry,
  REQUIRED_FIELD_ERROR,
  Form,
} from "common";
import { openSnackbar, closeModal } from "actions";
// import { isValidPhoneNumber } from "libphonenumber-js/mobile";
import { addPhoneNumberRequest, verifyPhoneNumberRequest } from "requests";
import { selectIsFetching } from "selectors";
import { INVALID_PHONE_ERROR } from "views/Auth/Login";
import { formKeys, useFormInputs } from "./useFormInputs";

let isValidPhoneNumber: any;

export const handleNewPhoneNumberError = ({
  errorCode,
  formKey,
  updateValues,
}: {
  errorCode: string;
  formKey: string;
  updateValues: Form["updateValues"];
}): void => {
  switch (errorCode) {
    case "60205": {
      updateValues([
        {
          key: formKey,
          error: "Este es un teléfono fijo. Introduce un teléfono móvil.",
        },
      ]);
      break;
    }
    case "invalid-phone-number": {
      updateValues([
        {
          key: formKey,
          error: INVALID_PHONE_ERROR,
        },
      ]);
      break;
    }
    case "phone-already-registered": {
      updateValues([
        {
          key: formKey,
          error: "Este teléfono ya se encuentra registrado",
        },
      ]);
      break;
    }
    default: {
      updateValues([
        {
          key: formKey,
          error: "Error al procesar este teléfono",
        },
      ]);
    }
  }
};

export const AddPhoneNumberModal = ({
  onPhoneAdded,
}: {
  onPhoneAdded?: (phoneAdded: string) => void;
}) => {
  const [hasSubmittedPhoneNumber, setHasSubmittedPhoneNumber] = useState(false);
  const dispatch = useDispatch();
  const country = useCountry();

  const formInputs = useFormInputs();
  const form = useForm(formInputs);

  const isFetchingAdd = useSelector((state) =>
    selectIsFetching(state, { request: addPhoneNumberRequest })
  );

  const isFetchingVerify = useSelector((state) =>
    selectIsFetching(state, { request: verifyPhoneNumberRequest })
  );

  const submitPhoneNumber = async () => {
    const { updateValues, getValuesObj } = form;
    const valuesObj = getValuesObj();
    const errors: FormInput[] = [];

    const phoneNumber = valuesObj[formKeys.phoneNumber];

    isValidPhoneNumber = (await import("libphonenumber-js/mobile"))
      .isValidPhoneNumber;

    if (!phoneNumber) {
      errors.push({ key: formKeys.phoneNumber, error: REQUIRED_FIELD_ERROR });
    } else if (!isValidPhoneNumber(phoneNumber, country.code)) {
      errors.push({ key: formKeys.phoneNumber, error: INVALID_PHONE_ERROR });
    }

    if (errors.length) {
      updateValues(errors);
      return;
    }

    dispatch(
      addPhoneNumberRequest(
        { phoneNumber, country: country.code },
        {
          onResponse: {
            successCallback: () => {
              setHasSubmittedPhoneNumber(true);
              updateValues([{ key: formKeys.phoneNumber, disabled: true }]);
            },
            errorCallback: (error) => {
              handleNewPhoneNumberError({
                errorCode: error.code,
                formKey: formKeys.phoneNumber,
                updateValues,
              });
            },
          },
        }
      )
    );
  };

  const submitCode = () => {
    const { updateValues, getValuesObj } = form;
    const valuesObj = getValuesObj();
    const errors: FormInput[] = [];

    const phoneNumber = valuesObj[formKeys.phoneNumber];
    const code = valuesObj[formKeys.code];

    if (!phoneNumber) {
      errors.push({ key: formKeys.phoneNumber, error: REQUIRED_FIELD_ERROR });
    } else if (!isValidPhoneNumber(phoneNumber, country.code)) {
      errors.push({ key: formKeys.phoneNumber, error: INVALID_PHONE_ERROR });
    }

    if (!code) {
      errors.push({ key: formKeys.code, error: REQUIRED_FIELD_ERROR });
    }

    if (errors.length) {
      updateValues(errors);
      return;
    }

    dispatch(
      verifyPhoneNumberRequest(
        { phoneNumber, code, country: country.code },
        {
          onResponse: {
            successCallback: () => {
              dispatch(
                openSnackbar({
                  variant: "success",
                  message: "Teléfono agregado",
                })
              );
              onPhoneAdded?.(phoneNumber);
              dispatch(closeModal());
            },
            errorCallback: () => {
              updateValues([
                { key: formKeys.code, error: "Código incorrecto" },
              ]);
            },
          },
        }
      )
    );
  };

  return (
    <Modal
      title="Agregar teléfono"
      loading={isFetchingAdd || isFetchingVerify}
      submitButton={{
        text: "Continuar",
        onClick: !hasSubmittedPhoneNumber ? submitPhoneNumber : submitCode,
      }}
    >
      <Container>
        <p>Ingresa los datos:</p>
        <TextField form={form} formKey={formKeys.phoneNumber} />
        {hasSubmittedPhoneNumber && (
          <>
            <p>
              Mandamos un mensaje al celular con un código. Puede tardar hasta 5
              minutos en llegar.
              <br />
              <br />
              Ingresa el código:
            </p>
            <TextField form={form} formKey={formKeys.code} />
          </>
        )}
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  width: 400px;
  max-width: 100%;
`;
