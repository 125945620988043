// Material Component
import MuiTextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import { KeyboardEvent } from "react";
import styled from "@emotion/styled";
import { useIsMobile, Form } from "common";

export const TextField = ({
  formKey,
  form,
  isMoney,
  onEnter,
  multiline,
}: {
  formKey: string;
  form: Form;
  isMoney?: boolean;
  onEnter?: () => void;
  multiline?: {
    minRows?: number;
  };
}) => {
  const { formValues, onChange } = form;
  const error = formValues[formKey]?.error;
  const isMobile = useIsMobile();

  const onKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      onEnter?.();
    }
  };

  return (
    <STextField
      size={isMobile ? "small" : "medium"}
      fullWidth
      multiline={Boolean(multiline)}
      minRows={multiline?.minRows}
      disabled={formValues[formKey]?.disabled}
      required={formValues[formKey]?.required}
      value={formValues[formKey]?.value ?? ""}
      label={formValues[formKey]?.label}
      type={formValues[formKey]?.type ?? "text"}
      placeholder={formValues[formKey]?.placeholder}
      error={Boolean(error)}
      helperText={error}
      onKeyPress={onKeyPress}
      onChange={({ target: { value } }) => onChange(formKey)(value)}
      InputProps={{
        startAdornment: isMoney ? (
          <InputAdornment position="start">$</InputAdornment>
        ) : undefined,
      }}
    />
  );
};

export const STextField = styled(MuiTextField)`
  textarea {
    line-height: 1.7em;
  }
`;
