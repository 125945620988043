// Material Icons
import ReportIcon from "@mui/icons-material/Report";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";

import styled from "@emotion/styled";
import { mediaStatusEnum } from "consts";
import { selectIsFetching, selectMedia } from "selectors";
import { useDispatch, useSelector, Button } from "common";
import { openModal } from "actions";
import { adminDeleteMediaRequest, adminPatchMediaRequest } from "requests";
import { ConfirmDeleteModal } from "./ConfirmDeleteModal";
import { theme } from "theme";
import { TId } from "types";

const VERIFIED: typeof mediaStatusEnum[number] = "verified";
const REJECTED: typeof mediaStatusEnum[number] = "rejected";

const contextBuilder = (status: string, mediaId: TId) => `${status}-${mediaId}`;

export const Verification = ({ mediaId }: { mediaId: TId }) => {
  const dispatch = useDispatch();
  const media = useSelector((state) => selectMedia(state, mediaId));

  const isFetchingReject = useSelector((state) =>
    selectIsFetching(state, {
      request: adminPatchMediaRequest,
      context: contextBuilder(REJECTED, mediaId),
    })
  );

  const isFetchingVerify = useSelector((state) =>
    selectIsFetching(state, {
      request: adminPatchMediaRequest,
      context: contextBuilder(VERIFIED, mediaId),
    })
  );

  const isFetchingDelete = useSelector((state) =>
    selectIsFetching(state, {
      request: adminDeleteMediaRequest,
      context: mediaId,
    })
  );

  const updateMedia = (newStatus: typeof mediaStatusEnum[number]) => () => {
    dispatch(
      adminPatchMediaRequest(
        {
          mediaId: mediaId,
          update: { status: newStatus },
        },
        { context: contextBuilder(newStatus, mediaId) }
      )
    );
  };

  const deleteMedia = () => {
    dispatch(openModal({ node: <ConfirmDeleteModal mediaId={mediaId} /> }));
  };

  return (
    <Container>
      <ButtonContainer
        color={theme.palette.error.main}
        lightColor={theme.palette.error.light}
      >
        <Button
          color="secondary"
          onClick={deleteMedia}
          loading={isFetchingDelete}
        >
          <p>Borrar</p>
          <DeleteForeverIcon />
        </Button>
      </ButtonContainer>

      <ButtonContainer
        color={theme.palette.error.main}
        lightColor={theme.palette.error.light}
        selected={REJECTED === media.status}
      >
        <Button
          color="secondary"
          onClick={updateMedia(REJECTED)}
          loading={isFetchingReject}
        >
          <p>Reject</p>
          <ReportIcon />
        </Button>
      </ButtonContainer>

      <ButtonContainer
        selected={VERIFIED === media.status}
        color={theme.palette.success.main}
        lightColor={theme.palette.success.light}
      >
        <Button
          color="secondary"
          onClick={updateMedia(VERIFIED)}
          loading={isFetchingVerify}
        >
          <p>Verify</p>
          <ThumbUpIcon />
        </Button>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  bottom: 0;
  border-radius: 8px;
  display: flex;
`;
const ButtonContainer = styled.div<{
  color: string;
  lightColor: string;
  selected?: boolean;
}>`
  button {
    position: relative;
    overflow: hidden;
    margin: 10px 20px 50px;
    ${({ selected, color }) => (selected ? `background: ${color};` : "")}

    p {
      margin-right: 10px;
    }

    &:hover {
      background: ${({ lightColor }) => lightColor};
    }
  }

  svg {
    color: ${({ color, selected }) => (selected ? "" : color)};
  }
`;
