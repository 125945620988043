export const MAX_UPLOAD_FILE_SIZE = 70 * 1024 * 1024;

export const countryEnum = ["MX", "CO", "ES"] as const;

export const mediaTypeEnum = ["video", "image"] as const;

export const transactionStatusEnum = [
  "pending",
  "expired",
  "cancelled",
  "complete",
];

export const postCategoryEnum = [
  "escorts",
  "masajes-eroticos",
  "travestis",
  "escorts-gay",
  "gigolos",
  "servicios-virtuales",
] as const;

export const mediaStatusEnum = [
  "approved",
  "verified",
  "rejected",
  "pending",
] as const;

export const HTTP_CODES = {
  OKAY: 200,
  CREATED: 201,
  SEE_OTHER: 303,
  UNAUTHORIZED: 401,
  BAD_REQUEST: 400,
  FORBIDDEN: 403,
  ERROR: 500,
} as const;

export const COUNTRY_DATA: {
  [country in (typeof countryEnum)[number]]: {
    code: (typeof countryEnum)[number];
    name: string;
    phoneCode: string;
    estado: string;
    prices: {
      currency: string;
      currencySymbol: string;
      featured: {
        [id: string]: {
          durationDays: number;
          options: {
            [id: string]: {
              durationHours: number;
              price: number;
            };
          };
        };
      };
      top: {
        [id: string]: {
          durationDays: number;
          options: {
            [id: string]: {
              durationHours: number;
              price: number;
            };
          };
        };
      };
      recargas: {
        [id: string]: {
          price: number;
        };
      };
    };
  };
} = {
  MX: {
    code: "MX",
    name: "México",
    phoneCode: "52",
    estado: "Estado",
    prices: {
      currency: "MXN",
      currencySymbol: "$",
      featured: {
        F7DzWTp0SzolQr3yCiKLI: {
          durationDays: 15,
          options: {
            IKhQL6WcSpRVAAy98DCl2: {
              durationHours: 24,
              price: 100,
            },
          },
        },
        "PltTsSfZ4aNWS3frm7-uM": {
          durationDays: 30,
          options: {
            "niYEM-A1FdPDGReD5desq": {
              durationHours: 24,
              price: 180,
            },
          },
        },
      },
      top: {
        iuknN39VlQi4LOT8om7ta: {
          durationDays: 7,
          options: {
            Ue9XIOUMgky6hg5cCC1cU: {
              durationHours: 12,
              price: 335,
            },
            HjI4T_A68BFUGVSGLo4tr: {
              durationHours: 24,
              price: 460,
            },
          },
        },
        "73l_wUvoOvgDBdCoaGcd5": {
          durationDays: 15,
          options: {
            yEEMLlWvuGT_1cZ3Xmxe0: {
              durationHours: 12,
              price: 530,
            },
            GgnghkTYV1CIqRAha70DX: {
              durationHours: 24,
              price: 795,
            },
          },
        },
        DyRGpGkuextmRgRZC88GC: {
          durationDays: 30,
          options: {
            HgK3Vj7GN0Zpc80_duUKi: {
              durationHours: 12,
              price: 865,
            },
            tX4Beh5BxMVVIqGLKOPko: {
              durationHours: 24,
              price: 1325,
            },
          },
        },
      },
      recargas: {
        "MqQ_9Q7eyRcN-TuQdgdGC": {
          price: 350,
        },
        iuknN39VlQi4LOT8om7ta: {
          price: 530,
        },
        "73l_wUvoOvgDBdCoaGcd5": {
          price: 800,
        },
        DyRGpGkuextmRgRZC88GC: {
          price: 1325,
        },
        Z9BUEb7iGwLiFkvhCYWYZ: {
          price: 2660,
        },
      },
    },
  },
  CO: {
    code: "CO",
    name: "Colombia",
    phoneCode: "+57",
    estado: "Departamento",
    prices: {
      currency: "COP",
      currencySymbol: "$",
      featured: {
        MtYCh4xNy80jQXrCB_fH9: {
          durationDays: 15,
          options: {
            "4f282DME8i3SoHQujQnvn": {
              durationHours: 24,
              price: 17000,
            },
          },
        },
        rohQDxmHQ7bpATBIQBp_K: {
          durationDays: 30,
          options: {
            "-3UiojBnH3f-hHOMM7dWC": {
              durationHours: 24,
              price: 29000,
            },
          },
        },
      },
      top: {
        "Ko6eHF3bBZzn1a-MfQ6OY": {
          durationDays: 7,
          options: {
            UQNh4dE4yTq77mH1zfTmV: {
              durationHours: 24,
              price: 50500,
            },
          },
        },
        "7XqoCHeXgAdKYp6x4kWX1": {
          durationDays: 15,
          options: {
            J4PRRiLIrcbr9av1L4SOq: {
              durationHours: 24,
              price: 87000,
            },
          },
        },
        VqdYoYbDUZqfRODr_32tX: {
          durationDays: 30,
          options: {
            W6go4G3dH5a3YXIJnPQ8K: {
              durationHours: 24,
              price: 145000,
            },
          },
        },
      },
      recargas: {
        "MqQ_9Q7eyRcN-TuQdgdGC": {
          price: 33000,
        },
        iuknN39VlQi4LOT8om7ta: {
          price: 50500,
        },
        "73l_wUvoOvgDBdCoaGcd5": {
          price: 87000,
        },
        DyRGpGkuextmRgRZC88GC: {
          price: 145000,
        },
      },
    },
  },
  ES: {
    code: "ES",
    name: "España",
    phoneCode: "+34",
    estado: "Provincia",
    prices: {
      currency: "EUR",
      currencySymbol: "€",
      featured: {
        VuOBdcuXpYbYjItas3vKX: {
          durationDays: 15,
          options: {
            "c_PSPSgczv8W-OYCIM5kk": {
              durationHours: 24,
              price: 11,
            },
          },
        },
        pkqHWZWVGmrkDNSR3QsJB: {
          durationDays: 30,
          options: {
            G_cw_qCvAG5i6WzyELyG0: {
              durationHours: 24,
              price: 18,
            },
          },
        },
      },
      top: {
        "Ko6eHF3bBZzn1a-MfQ6OY": {
          durationDays: 7,
          options: {
            "MibMQhNExpm0-iSUuUgbU": {
              durationHours: 24,
              price: 40,
            },
          },
        },
        "7XqoCHeXgAdKYp6x4kWX1": {
          durationDays: 15,
          options: {
            Pd7AnQCEPbFCpl_WYp4t2: {
              durationHours: 24,
              price: 70,
            },
          },
        },
        VqdYoYbDUZqfRODr_32tX: {
          durationDays: 30,
          options: {
            "Swml_iucwbCdRPStEF-Qw": {
              durationHours: 24,
              price: 105,
            },
          },
        },
      },
      recargas: {
        "MqQ_9Q7eyRcN-TuQdgdGC": {
          price: 25,
        },
        iuknN39VlQi4LOT8om7ta: {
          price: 40,
        },
        "73l_wUvoOvgDBdCoaGcd5": {
          price: 70,
        },
        DyRGpGkuextmRgRZC88GC: {
          price: 105,
        },
      },
    },
  },
} as const;
