// Material Components
import Card from "@mui/material/Card";
// lodash
import transform from "lodash/transform";
import map from "lodash/map";

import styled from "@emotion/styled";
import { useEffect, RefObject, createRef, useRef } from "react";
import {
  useIsMobile,
  Image,
  useDispatch,
  Modal,
  Video,
  useSelector,
} from "common";
import { selectMediaByIds } from "selectors";
import { closeModal } from "actions";
import { Review, Status, Delete } from "./components";
import { TId, TMedia } from "types";

export const GalleryModal = ({
  selectedMedia,
  mediaIds,
  showStatus,
  showDelete,
  isReview,
}: {
  selectedMedia: TMedia;
  mediaIds: Array<TId>;
  showStatus?: boolean;
  showDelete?: boolean;
  isReview?: {
    approval?: boolean;
    verification?: boolean;
  };
}) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const media = useSelector((state) => selectMediaByIds(state, mediaIds));

  const refsMap = useRef(
    transform(
      mediaIds,
      (obj, mediaId) => {
        obj[mediaId] = createRef();
      },
      {} as { [id in TId]: RefObject<HTMLDivElement> }
    )
  );

  useEffect(() => {
    refsMap.current[selectedMedia.id].current?.scrollIntoView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SModal
      cancelButton={{
        hide: true,
      }}
      submitButton={{
        text: "Cerrar Galería",
        onClick: () => {
          dispatch(closeModal());
        },
      }}
      title="Fotos"
    >
      {map(media, (media) => (
        <SCard key={media.id} ref={refsMap.current[media.id]}>
          <MediaContainer>
            {media.type === "image" ? (
              <Image
                media={media}
                size={isMobile ? "small" : "large"}
                alt="escort"
              />
            ) : (
              <Video media={media} />
            )}
            {isReview && <Review mediaId={media.id} isReview={isReview} />}
            {showStatus && <Status media={media} />}
            {showDelete && <Delete media={media} />}
          </MediaContainer>
        </SCard>
      ))}
    </SModal>
  );
};

const SModal = styled(Modal)`
  border: none;
  padding: 20px 15px !important;
  padding-bottom: 100px !important;

  .MuiDialogActions-root {
    position: fixed;
    bottom: 20px;
    right: 60px;
    z-index: 1;
  }
`;
const SCard = styled(Card)`
  padding: 13px;
  margin-bottom: 20px;
  min-width: 200px;
  min-height: 200px;
`;
const MediaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  img {
    max-width: 100%;
    z-index: 1;
  }
`;
