// lodash
import map from "lodash/map";
import concat from "lodash/concat";
import toArray from "lodash/toArray";
import filter from "lodash/filter";
import compact from "lodash/compact";

import { useRef, ChangeEvent, useState } from "react";
import styled from "@emotion/styled";
import { useDispatch } from "common";
import { openSnackbar } from "actions";
import { mixpanelFileSelect, mixpanelFileTooBig } from "mixpanelEventActions";
import { nanoid } from "nanoid";
import { NewFile } from "types";
import { MAX_UPLOAD_FILE_SIZE } from "consts";

export const useFileSelect = ({
  onChange,
}: {
  onChange: (files: Array<NewFile>) => void;
}) => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState<Array<NewFile>>([]);
  const inputRef = useRef<any>(null);

  const openFileInput = () => {
    if (!inputRef.current) return;
    inputRef.current.click();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = compact(
      map(event.target.files, (file) => {
        if (file.size > MAX_UPLOAD_FILE_SIZE) {
          dispatch(mixpanelFileTooBig({ fileSize: file.size }));

          dispatch(
            openSnackbar({
              variant: "error",
              message: "Archivo demasiado grande",
            })
          );
          return null;
        }

        dispatch(mixpanelFileSelect({ fileSize: file.size }));

        return {
          file,
          url: URL.createObjectURL(file),
          temporaryId: nanoid(),
        };
      })
    );

    const newFiles = concat(files, toArray(selectedFiles));
    setFiles(newFiles);
    onChange(newFiles);
  };

  const onRemoveIndex = (index: number) => {
    const newFiles = filter(files, (_, idx) => index !== idx);
    setFiles(newFiles);
    onChange(newFiles);
  };

  // This has to be added somewhere in the component
  // for this hook to work.
  // Nothing is actually rendered. The input is opened when
  // calling openFileInput
  // <FileInput />
  const FileInput = () => (
    <Input
      ref={inputRef}
      type="file"
      multiple
      accept="image/*,video/*"
      onChange={handleChange}
    />
  );

  return {
    openFileInput,
    onRemoveIndex,
    FileInput,
    setFiles,
    files,
  };
};

const Input = styled.input`
  display: none;
`;
