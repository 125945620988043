import { useSelector } from "common";
import { selectMedia } from "selectors";
import { Approval } from "./Approval";
import { Verification } from "./Verification";
import { TId } from "types";

export const Review = ({
  mediaId,
  isReview,
}: {
  mediaId: TId;
  isReview: { approval?: boolean; verification?: boolean };
}) => {
  const media = useSelector((state) => selectMedia(state, mediaId));
  if (!media) return null;

  return isReview.approval ? (
    <Approval mediaId={mediaId} />
  ) : (
    <Verification mediaId={mediaId} />
  );
};
