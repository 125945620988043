// lodash
import assign from "lodash/assign";

import { put, select, all, takeEvery } from "redux-saga/effects";
import mixpanel from "mixpanel-browser";
import { PayloadAction } from "@reduxjs/toolkit";
import { setCurrentUserId } from "actions";
import { LOCATION_CHANGE } from "connected-next-router";
import { mixpanelTrack, mixpanelNavigation } from "mixpanelEventActions";
import { selectCurrentUser, selectLastLocations } from "selectors";
import { TUser, MixpanelEvent, MixpanelUserProps } from "types";
import { IS_DEV } from "envVars";

function* trackNavigation() {
  const lastLocations: Array<string> = yield select(selectLastLocations);
  const [currentLocation, lastLocation] = lastLocations;
  if (currentLocation === lastLocation) return;
  yield put(mixpanelNavigation({ currentLocation, lastLocation }));
}

function* eventListener({ payload }: PayloadAction<MixpanelEvent>) {
  const user: TUser | undefined = yield select(selectCurrentUser);
  const userProps: MixpanelUserProps = { credits: user?.credits };
  const trackProps = assign(userProps, payload.props);
  mixpanel.track(payload.event, trackProps);
}

function identifyUser({ payload }: PayloadAction<string>) {
  mixpanel.identify(payload);
}

export function* mixpanelSagas() {
  if (IS_DEV) return;
  yield all([
    takeEvery(mixpanelTrack, eventListener),
    takeEvery(LOCATION_CHANGE, trackNavigation),
    takeEvery(setCurrentUserId, identifyUser),
  ]);
}
