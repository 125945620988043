// lodash
import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";
import concat from "lodash/concat";
import unset from "lodash/unset";
import map from "lodash/map";

import { put, all, call, takeLeading } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  deleteMediaRequest,
  patchPostRequest,
  createPostRequest,
} from "requests";
import { uploadMediaSaga, UploadMediaSagaReturnType } from "../media/sagas";
import { push } from "connected-next-router";
import { createPost } from "actions";
import { awaitResponse, endRequestAction } from "utils";
import { CreatePostPayload, PostPostBody, TId } from "types";
import routes from "routes";

export function* deleteCreatedMedia(mediaIds: Array<TId>) {
  const deleteMediaRequests = map(mediaIds, (mediaId) =>
    call(awaitResponse, deleteMediaRequest({ mediaId }))
  );

  yield all(deleteMediaRequests);
}

function* createPostSaga({ payload }: PayloadAction<CreatePostPayload>) {
  const {
    updatePostId,
    estado,
    localidad,
    barrio,
    category,
    title,
    text,
    noAdvancePayment,
    phoneNumber,
    showWhatsapp,
    showPhoneNumber,
    newFiles,
    existingMedia,
  } = payload;

  const {
    responses,
    uploadedVideoIds,
    uploadedImageIds,
  }: UploadMediaSagaReturnType = yield call(uploadMediaSaga, newFiles);

  const errors = compact(map(responses, "error"));
  const uploadedMediaIds = concat(uploadedImageIds, uploadedVideoIds);

  if (!isEmpty(errors)) {
    yield put(
      endRequestAction({
        request: createPost,
        requestStep: "error",
        params: { payload: { errors, devMessage: "errors uploading media" } },
      })
    );

    yield call(deleteCreatedMedia, uploadedMediaIds);
    return;
  }

  const mediaIds = concat(uploadedMediaIds, map(existingMedia, "id"));

  const newPost: PostPostBody = {
    estado,
    noAdvancePayment,
    localidad,
    barrio,
    category,
    title,
    text,
    phoneNumber,
    showWhatsapp,
    showPhoneNumber,
    mediaIds,
  };

  // Clean data from optional values that are falsey
  // or the jsonschema validation will fail
  if (!newPost.barrio) {
    unset(newPost, "barrio");
  }

  const { success: createPostSuccess, error: createPostError } = yield call(
    awaitResponse,
    updatePostId
      ? patchPostRequest({ update: newPost, postId: updatePostId! })
      : createPostRequest(newPost)
  );

  if (createPostError) {
    yield put(
      endRequestAction({
        request: createPost,
        requestStep: "error",
        params: {
          payload: {
            error: createPostError,
            devMessage: "Error creating post",
          },
        },
      })
    );
    yield call(deleteCreatedMedia, uploadedMediaIds);
    return;
  }

  yield put(
    endRequestAction({
      request: createPost,
      requestStep: "success",
      params: { payload: createPostSuccess.payload },
    })
  );

  yield put(push(routes.user()));
}

export function* postsSagas() {
  yield all([takeLeading(createPost, createPostSaga)]);
}
