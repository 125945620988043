// lodash
import without from "lodash/without";
import omit from "lodash/omit";
import uniq from "lodash/uniq";
import unset from "lodash/unset";
import concat from "lodash/concat";
import compact from "lodash/compact";
import forEach from "lodash/forEach";

import { createReducer } from "@reduxjs/toolkit";
import {
  getCurrentUserPostsRequest,
  patchPostRequest,
  deletePostRequest,
  getPostsRequest,
} from "requests";
import { buildQueryString } from "common";
import {
  updateFavouritePosts,
  setCurrentQueryResults,
  assignNormalizedValues,
  setPostBackLink,
} from "actions";
import { successActionType } from "utils";
import {
  AssignNormalizedValuesPayload,
  TId,
  GetPostsQuery,
  ResponseAction,
  PostsState,
  PatchPostResponse,
} from "types";

const initialState: PostsState = {
  byId: {},
  mediaIdsByPostId: {},
  currentQueryResults: [],
  userPostsById: {},
  fetchedAt: {},
  favouritePostIds: [],
};

export const postsReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(assignNormalizedValues, (state, { payload }) => {
      const { data } = payload;
      // Update Posts
      forEach(data?.posts, (post) => {
        if (state.userPostsById[post.id]) {
          // If we update a user post, we want to update them
          // locally with the raw data
          state.userPostsById[post.id] = post;
        }

        if (!post.top) {
          state.byId[post.id] = post;
          return;
        }

        // We check here if posts which have a top package
        // are within the top schedule. If they're not,
        // we remove the `top`, property from them
        const { startingDate, durationHours, durationDays } = post.top;

        // If the duration is 24 hours, we're always
        // within schedule
        if (durationHours === 24) {
          state.byId[post.id] = post;
          return;
        }

        const startDate = new Date(startingDate);
        const now = new Date();

        if (now < startDate) {
          state.byId[post.id] = omit(post, "top");
          return;
        }

        const endDate = new Date(startingDate);
        endDate.setDate(endDate.getDate() + durationDays);
        endDate.setHours(endDate.getHours() + durationHours);

        if (now > endDate) {
          state.byId[post.id] = omit(post, "top");
          return;
        }

        const todaysScheduleStart = new Date();
        todaysScheduleStart.setHours(startDate.getHours());
        todaysScheduleStart.setMinutes(0);
        todaysScheduleStart.setSeconds(0);

        const todaysScheduleEnd = new Date(todaysScheduleStart);
        todaysScheduleEnd.setHours(
          todaysScheduleEnd.getHours() + durationHours
        );

        if (now >= todaysScheduleStart) {
          if (now <= todaysScheduleEnd) {
            state.byId[post.id] = post;
            return;
          }

          state.byId[post.id] = omit(post, "top");
          return;
        }

        const yesterdaysScheduleEnd = new Date(todaysScheduleEnd);
        yesterdaysScheduleEnd.setDate(yesterdaysScheduleEnd.getDate() - 1);

        if (now <= yesterdaysScheduleEnd) {
          const yesterdaysScheduleStart = new Date(todaysScheduleStart);
          yesterdaysScheduleStart.setDate(
            yesterdaysScheduleStart.getDate() - 1
          );

          if (now >= yesterdaysScheduleStart) {
            state.byId[post.id] = post;
            return;
          }
        }

        state.byId[post.id] = omit(post, "top");
      });

      forEach(data?.postsMedia, (postMediaItem) => {
        state.mediaIdsByPostId[postMediaItem.postId] = uniq(
          compact(
            concat(
              postMediaItem.mediaId,
              state.mediaIdsByPostId[postMediaItem.postId]
            )
          )
        );
      });
    })
    .addCase(
      successActionType(getPostsRequest.type),
      (state, { payload }: ResponseAction<{ query: GetPostsQuery }>) => {
        const stringifiedQuery = buildQueryString(payload.data!.query);
        state.fetchedAt[stringifiedQuery] = new Date();
      }
    )
    .addCase(setCurrentQueryResults, (state, { payload }) => {
      state.currentQueryResults = payload;
    })
    .addCase(updateFavouritePosts, (state, { payload }) => {
      const { action, postId } = payload;
      if (action === "add") {
        state.favouritePostIds.push(postId);
      } else {
        state.favouritePostIds = without(state.favouritePostIds, postId);
      }
    })
    .addCase(setPostBackLink, (state, { payload }) => {
      state.backLink = payload;
    })
    .addCase(
      successActionType(getCurrentUserPostsRequest.type),
      (state, { payload }: ResponseAction<AssignNormalizedValuesPayload>) => {
        forEach(payload.data?.posts, (post) => {
          state.userPostsById[post.id] = post;
        });
      }
    )
    .addCase(
      successActionType(deletePostRequest.type),
      (state, { payload }: ResponseAction<{ postId: TId }>) => {
        unset(state.byId, [payload.data!.postId]);
        unset(state.userPostsById, [payload.data!.postId]);
      }
    )
    .addCase(
      successActionType(patchPostRequest.type),
      (state, { payload }: ResponseAction<PatchPostResponse>) => {
        unset(state.byId, [payload.data!.deletedPostId]);
        unset(state.userPostsById, [payload.data!.deletedPostId]);
      }
    )
);
