// Material Components
import IconButton from "@mui/material/IconButton";
import UserFoundIcon from "@mui/icons-material/Person";
import UserNotFoundIcon from "@mui/icons-material/PermIdentity";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import CreateIcon from "@mui/icons-material/Create";
// lodash
import isEmpty from "lodash/isEmpty";

import Link from "next/link";
import styled from "@emotion/styled";
import { useSelector } from "common";
import { selectCurrentUser, selectFavouritePostIds } from "selectors";
import { theme } from "theme";
import routes from "routes";

export const TopBar = () => {
  const user = useSelector(selectCurrentUser);
  const favouritePostIds = useSelector(selectFavouritePostIds);

  const UserIcon = () => (user ? <UserFoundIcon /> : <UserNotFoundIcon />);
  const FavouritesIcon = () =>
    isEmpty(favouritePostIds) ? <FavoriteBorderIcon /> : <FavoriteIcon />;

  const Navigation = () => (
    <NavigationContainer>
      <Link
        href={user ? routes.user() : routes.login()}
        passHref
        prefetch={false}
      >
        <NavItem>
          <IconButton size="small" aria-label="usuario">
            <UserIcon />
          </IconButton>
          <Hidden breakpoint={theme.breakpoints.down("sm")}>
            <span>USUARIO</span>
          </Hidden>
        </NavItem>
      </Link>

      <Hidden breakpoint={theme.breakpoints.down("md")}>
        <Link href={routes.publicarAnuncio()} passHref prefetch={false}>
          <NavItem blue>
            <IconButton size="small" aria-label="publicar anuncio">
              <CreateIcon />
            </IconButton>
            <span>PUBLICAR ANUNCIO GRATIS</span>
          </NavItem>
        </Link>
      </Hidden>

      <Link href={routes.favoritos()} passHref prefetch={false}>
        <NavItem>
          <IconButton size="small" aria-label="favoritos">
            <FavouritesIcon />
          </IconButton>
          <Hidden breakpoint={theme.breakpoints.down("sm")}>
            <span>FAVORITOS</span>
          </Hidden>
        </NavItem>
      </Link>
    </NavigationContainer>
  );

  return (
    <Container>
      <Content>
        <Link href={routes.home()} passHref prefetch={false}>
          <div>
            <Logo>LOCAL</Logo>
            <Logo blue>EROTICA</Logo>
          </div>
        </Link>
        <Navigation />
      </Content>

      <Hidden breakpoint={theme.breakpoints.up("md")}>
        <PublicarAnuncioContainer>
          <Link href={routes.publicarAnuncio()} passHref prefetch={false}>
            <NavItem blue>
              <IconButton size="small" aria-label="publicar anuncio">
                <CreateIcon />
              </IconButton>
              <span>PUBLICAR ANUNCIO GRATIS</span>
            </NavItem>
          </Link>
        </PublicarAnuncioContainer>
      </Hidden>
    </Container>
  );
};

const Container = styled.div`
  padding: 20px 45px;

  ${theme.breakpoints.down("sm")} {
    padding: 10px 30px;
  }

  ${theme.breakpoints.only("xs")} {
    padding: 10px 10px;
  }
`;
const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;
const Logo = styled.span<{ blue?: boolean }>`
  font-size: 1.7rem;
  color: ${({ blue }) =>
    blue ? theme.palette.blue.main : theme.palette.primary.main};
  cursor: pointer;
  text-decoration: underline;
}
`;
const Hidden = styled.div<{ breakpoint: string }>`
  ${({ breakpoint }) => `
    ${breakpoint} {
      display: none;
    }
  `}
`;
const NavigationContainer = styled.div`
  margin-left: auto;
  display: flex;
`;
const NavItem = styled.div<{ blue?: boolean }>`
  display: flex;
  align-items: center;
  margin-left: 10px;

  span {
    cursor: pointer;
    text-decoration: underline;
    font-size: 1.3em;
    color: ${({ blue }) =>
      blue ? theme.palette.blue.main : theme.palette.primary.main};
  }

  svg {
    font-size: 2rem;
    color: ${({ blue }) =>
      blue ? theme.palette.blue.main : theme.palette.primary.main};
  }
`;
const PublicarAnuncioContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  span {
    font-size: 1.2rem;
  }

  svg {
    font-size: 1.4rem;
  }
`;
