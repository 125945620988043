export const NEXT_PUBLIC_S3_ENDPOINT = process.env.NEXT_PUBLIC_S3_ENDPOINT;
export const NEXT_PUBLIC_API_ENDPOINT = process.env.NEXT_PUBLIC_API_ENDPOINT;
export const NEXT_PUBLIC_COUNTRY_CODE = process.env.NEXT_PUBLIC_COUNTRY_CODE;
export const NEXT_PUBLIC_S3_BUCKET = process.env.NEXT_PUBLIC_S3_BUCKET;
export const NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN =
  process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN;
export const NEXT_PUBLIC_ADMIN_WHATSAPP =
  process.env.NEXT_PUBLIC_ADMIN_WHATSAPP!;

export const IS_DEV = process.env.NODE_ENV === "development";

// We shorten the commit hash
export const NEXT_PUBLIC_COMMIT_HASH = process.env.NEXT_PUBLIC_COMMIT_HASH
  ? process.env.NEXT_PUBLIC_COMMIT_HASH.substring(0, 7)
  : "dev";
