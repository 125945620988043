// lodash
import forEach from "lodash/forEach";

// import LogRocket from "logrocket";
import { createReducer } from "@reduxjs/toolkit";
import { setCurrentUserId, assignNormalizedValues } from "actions";
import { UsersState } from "types";

const initialState: UsersState = { byId: {} };

export const usersReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(assignNormalizedValues, (state, { payload }) => {
      const { data } = payload;
      forEach(data?.users, (user) => {
        state.byId[user.id] = user;
      });
    })
    .addCase(setCurrentUserId, (state, { payload }) => {
      // if (payload) LogRocket.identify(payload);
      state.currentUserId = payload;
    })
);
