// lodash
import every from "lodash/every";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import some from "lodash/some";

import { TId } from "types";
import { useSelector } from "common";
import {
  selectPost,
  selectMediaByIds,
  selectMediaIdsByPostId,
} from "selectors";

type Props = {
  // postId, so we select the post from the posts reducer
  // state.posts.byId, instead of using the post from
  // state.userPostsById, to include/exclude top/featured
  // data from the post if out of schedule
  // (post reducer handles that), instead of including post
  // data that's included in userPostsById
  postId?: TId;
};

// TODO(gualcm): update implementation if we allow for hourly featured packages
type PostTags = {
  verified: boolean;
  top: boolean;
  featured: boolean;
  noAdvancePayment: boolean;
  hasVideo: boolean;
  commentsEnabled: boolean;
};

export const usePostTags = ({ postId }: Props): PostTags => {
  const post = useSelector((state) => selectPost(state, postId));
  const mediaIds = useSelector((state) =>
    post ? selectMediaIdsByPostId(state, post.id) : []
  );
  const media = useSelector((state) => selectMediaByIds(state, mediaIds));
  const mediaStatusIsVerified = map(
    media,
    (mediaItem) => mediaItem.status === "verified"
  );

  return {
    verified: !isEmpty(media) && every(mediaStatusIsVerified),
    top: Boolean(post?.top),
    featured: Boolean(post?.featured),
    noAdvancePayment: Boolean(post?.noAdvancePayment),
    hasVideo: some(media, (mediaItem) => mediaItem.type === "video"),
    commentsEnabled: true,
  };
};
