// lodash
import forEach from "lodash/forEach";

import { createReducer } from "@reduxjs/toolkit";
import { assignNormalizedValues } from "actions";
import {
  adminGetPendingVerifyMediaRequest,
  adminDeleteMediaRequest,
  deleteMediaRequest,
} from "requests";
import { successActionType } from "utils";
import {
  AdminGetPendingMediaResponse,
  ResponseAction,
  TId,
  MediaState,
} from "types";

const initialState: MediaState = { byId: {} };

export const mediaReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(assignNormalizedValues, (state, { payload }) => {
      const { data } = payload;
      forEach(data?.media, (media) => {
        state.byId[media.id] = media;
      });
    })
    .addCase(
      successActionType(adminDeleteMediaRequest.type),
      (state, { payload }: ResponseAction<{ mediaId: TId }>) => {
        delete state.byId[payload.data!.mediaId];
      }
    )
    .addCase(
      successActionType(adminGetPendingVerifyMediaRequest.type),
      (state, { payload }: ResponseAction<AdminGetPendingMediaResponse>) => {
        forEach(payload.data?.groupedByUser, (media) => {
          forEach(media, (mediaItem) => {
            state.byId[mediaItem.id] = mediaItem;
          });
        });
      }
    )
    .addCase(
      successActionType(deleteMediaRequest.type),
      (state, { payload }: ResponseAction<{ mediaId: TId }>) => {
        delete state.byId[payload.data!.mediaId];
      }
    )
);
