import styled from "@emotion/styled";
import { theme } from "theme";

export const Top = () => (
  <Container>
    <span>TOP</span>
  </Container>
);

const Container = styled.div`
  border: 2px solid ${theme.palette.highlight.main};
  color: ${theme.palette.highlight.main};
  border-radius: 3px;
  padding: 3px;
  width: 45px;
  text-align: center;
`;
