import { Action } from "@reduxjs/toolkit";
import { RootState } from "types";

import { DEFAULT_CONTEXT } from "./reducer";

export const selectError = (
  state: RootState,
  { request, context }: { request: Action; context?: string }
) => state.api.errors[context || DEFAULT_CONTEXT]?.[request.type];

export const selectIsFetching = (
  state: RootState,
  { request, context }: { request?: Action; context?: string }
): boolean =>
  request
    ? Boolean(state.api.isFetching[context || DEFAULT_CONTEXT]?.[request.type])
    : Boolean(state.api.isFetching[context || DEFAULT_CONTEXT]);

export const selectPaginationInfo = (
  state: RootState,
  { request, context }: { request: Action; context?: string }
) => state.api.pagination[context || DEFAULT_CONTEXT]?.[request.type];
