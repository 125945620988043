import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "common";
import { selectIsFetching, selectCurrentUser } from "selectors";
import { getCurrentUserRequest } from "requests";
import routes from "routes";
import { push } from "connected-next-router";

const REDIRECT_DELAY = 600;

type Props = {
  redirectRoute: string;
};

export const usePrivateRoute = (props?: Props) => {
  const dispatch = useDispatch();
  const [navigate, setNavigate] = useState(false);
  const user = useSelector(selectCurrentUser);

  const isFetchingUser = useSelector((state) =>
    selectIsFetching(state, {
      request: getCurrentUserRequest,
    })
  );

  // If there's no userData and we're not fetching the userData,
  // we give the app time for the redux store to update
  // and then we check if there's still no userData.
  //
  // If there's not, we redirect the user to the login screen.
  useEffect(() => {
    if (user || isFetchingUser) return;
    const timer = setTimeout(() => setNavigate(true), REDIRECT_DELAY);
    return () => clearTimeout(timer);
  }, [isFetchingUser, user]);

  useEffect(() => {
    if (!navigate) return;
    if (!user) dispatch(push(props?.redirectRoute ?? routes.login()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  return { user };
};
