// Material Components
import FormControlLabel from "@mui/material/FormControlLabel";
import MuiCheckbox from "@mui/material/Checkbox";

import styled from "@emotion/styled";
import { Form } from "common";
import { theme } from "theme";

export const Checkbox = ({
  formKey,
  form,
}: {
  formKey: string;
  form: Form;
}) => {
  const { formValues, onChange } = form;
  const error = formValues[formKey]?.error;
  return (
    <Container>
      <FormControlLabel
        control={
          <MuiCheckbox
            color="primary"
            checked={formValues[formKey]?.value ?? false}
            disabled={formValues[formKey]?.disabled}
            onChange={({ target: { checked } }) => onChange(formKey)(checked)}
          />
        }
        label={formValues[formKey]?.label}
      />
      {error && <p>{error}</p>}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  margin-bottom: 10px;

  p {
    color: ${theme.palette.error.main};
  }
`;
