// Material Components
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import styled from "@emotion/styled";
import { useDispatch, Button } from "common";
import { openModal } from "actions";
import { ConfirmModal } from "./ConfirmModal";
import { TMedia } from "types";
import { theme } from "theme";

export const Delete = ({ media }: { media: TMedia }) => {
  const dispatch = useDispatch();

  const deleteMedia = () => {
    dispatch(openModal({ node: <ConfirmModal media={media} /> }));
  };

  return (
    <Container>
      <Button onClick={deleteMedia}>
        <ButtonContent>
          <DeleteForeverIcon /> Borrar
        </ButtonContent>
      </Button>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;

  button {
    background: ${theme.palette.error.main} !important;
  }
`;
const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  color: white;
`;
