export const RawOxxoSvg = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="955px"
    height="442px"
    viewBox="0 0 955 442"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>oxxo</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="oxxo" transform="translate(0.000000, 0.749509)">
        <path
          d="M-0.00375147309,29.8996334 C-0.00375147309,13.5432107 13.3765026,0.162956658 29.7329252,0.162956658 L925.257071,0.162956658 C941.614744,0.162956658 954.994998,13.5432107 954.994998,29.8996334 L954.994998,410.581616 C954.994998,426.940539 941.614744,440.319543 925.257071,440.319543 L29.7329252,440.319543 C13.3765026,440.319543 -0.00375147309,426.940539 -0.00375147309,410.581616 L-0.00375147309,29.8996334 Z"
          id="Path"
          fill="#A9B6C4"
        ></path>
        <polygon
          id="Path"
          fill="#FFFFFF"
          points="-0.00375147309 43.3786762 954.996249 43.3786762 954.996249 397.098822 -0.00375147309 397.098822"
        ></polygon>
        <path
          d="M809.334051,135.244749 C765.640644,135.244749 730.022908,170.867487 730.022908,214.560894 C730.022908,258.238045 765.640644,293.865785 809.334051,293.865785 C853.018705,293.865785 888.637692,258.238045 888.637692,214.560894 C888.637692,170.867487 853.018705,135.244749 809.334051,135.244749 Z"
          id="Path"
          fill="#A9B6C4"
        ></path>
        <path
          d="M155.624859,135.244749 C111.927701,135.244749 76.3049627,170.867487 76.3049627,214.560894 C76.3049627,258.238045 111.927701,293.865785 155.624859,293.865785 C199.30201,293.865785 234.925998,258.238045 234.925998,214.560894 C234.925998,170.867487 199.30201,135.244749 155.624859,135.244749 Z"
          id="Path"
          fill="#A9B6C4"
        ></path>
        <path
          d="M803.109107,65.4435904 C729.690278,63.756678 695.172974,110.835164 645.077053,168.901715 L597.819746,223.668221 L672.652881,313.254648 C690.481131,340.780457 656.45402,368.098684 635.34323,342.911294 L566.156063,260.378886 L498.737089,338.534575 C477.267409,363.413094 443.626699,335.620931 461.836349,308.352724 L535.025088,223.243054 L487.07626,166.043093 L517.920872,127.843093 L566.68502,186.421095 L608.720276,137.524394 C629.345875,113.54998 650.380385,81.7800052 677.64234,65.4435904 L-0.00250098206,65.4435904 L-0.00250098206,375.04016 L158.433462,375.04016 C231.862295,375.04016 265.195384,331.137921 313.933272,271.935924 L359.92133,216.07774 L283.028637,128.253254 C264.586395,101.142608 297.965752,73.0553293 319.655519,97.7462747 L390.718423,178.6668 L456.33669,98.9717559 C477.221141,73.5930405 511.487096,100.616152 493.915196,128.299522 L422.704734,215.082349 L471.964076,271.16312 L440.926889,307.916302 L391.896386,252.617088 L351.005329,302.457909 C330.934948,326.902508 310.618221,358.07975 283.735164,375.04016 L954.997499,375.04016 L954.997499,65.4435904 L803.109107,65.4435904 L803.109107,65.4435904 Z M280.916558,214.560894 C280.916558,283.579246 224.639459,339.862597 155.624859,339.862597 C86.5927524,339.862597 30.3156541,283.579246 30.3156541,214.560894 C30.3156541,145.531288 86.5927524,89.2529396 155.624859,89.2529396 C224.639459,89.2529396 280.916558,145.531288 280.916558,214.560894 Z M809.334051,339.862597 C740.314449,339.862597 684.024846,283.579246 684.024846,214.560894 C684.024846,145.531288 740.314449,89.2529396 809.334051,89.2529396 C878.357405,89.2529396 934.633253,145.531288 934.633253,214.560894 C934.633253,283.579246 878.357405,339.862597 809.334051,339.862597 Z"
          id="Shape"
          fill="#A9B6C4"
        ></path>
      </g>
    </g>
  </svg>
);
