import CircularProgress from "@mui/material/CircularProgress";
import styled from "@emotion/styled";

type Props = {
  size?: number;
  className?: string;
};

export const Loading = ({ size, className }: Props) => (
  <Container className={className}>
    <CircularProgress size={size || 130} aria-label="loading" />
  </Container>
);

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;
