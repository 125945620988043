// lodash
import sortBy from "lodash/sortBy";
import forEach from "lodash/forEach";
import map from "lodash/map";

import { createReducer } from "@reduxjs/toolkit";
import {
  adminGetPostsToApproveRequest,
  adminGetPendingVerifyMediaRequest,
} from "requests";
import { successActionType } from "utils";
import {
  AssignNormalizedValuesPayload,
  AdminGetPendingMediaResponse,
  AdminState,
  ResponseAction,
} from "types";

const initialState: AdminState = {
  postsToApproveIds: [],
  verificationMediaIdsByUserId: {},
};

export const adminReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(
      successActionType(adminGetPostsToApproveRequest.type),
      (
        state,
        { payload: { data } }: ResponseAction<AssignNormalizedValuesPayload>
      ) => {
        const sortedPosts = sortBy(data?.posts, "createdAt");
        state.postsToApproveIds = map(sortedPosts, "id");
      }
    )
    .addCase(
      successActionType(adminGetPendingVerifyMediaRequest.type),
      (state, { payload }: ResponseAction<AdminGetPendingMediaResponse>) => {
        state.verificationMediaIdsByUserId = {};
        forEach(payload.data?.groupedByUser, (media, userId) => {
          state.verificationMediaIdsByUserId[userId] = map(media, "id");
        });
      }
    )
);
