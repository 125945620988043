import { createAction } from "@reduxjs/toolkit";
import { MixpanelEvent } from "types";

export const mixpanelTrack = createAction<MixpanelEvent>("mixpanelTrack");

export const mixpanelSuccessfulLogin = () =>
  mixpanelTrack({ event: "successfull-login" });

export const mixpanelFileSelect = ({ fileSize }: { fileSize: number }) =>
  mixpanelTrack({ event: "file-select", props: { fileSize } });

export const mixpanelFileTooBig = ({ fileSize }: { fileSize: number }) =>
  mixpanelTrack({ event: "file-too-big", props: { fileSize } });

export const mixpanelPostContact = ({
  phoneNumber,
  method,
}: {
  phoneNumber: string;
  method: "whatsapp" | "phonecall";
}) => mixpanelTrack({ event: "postContact", props: { phoneNumber, method } });

export const mixpanelNavigation = ({
  currentLocation,
  lastLocation,
}: {
  currentLocation: string | null;
  lastLocation: string | null;
}) =>
  mixpanelTrack({
    event: "navigation",
    props: {
      currentLocation,
      lastLocation,
    },
  });
