// Material Components
import Hidden from "@mui/material/Hidden";
import useMediaQuery from "@mui/material/useMediaQuery";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
// lodash
import truncate from "lodash/truncate";
import filter from "lodash/filter";
import size from "lodash/size";
import random from "lodash/random";

import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { Image, usePostTags, useSelector } from "common";
import { Top, verifiedImageSrc, cameraImageSrc } from "res";
import { Location } from "./Location";
import { Favourite } from "./Favourite";
import { selectMediaIdsByPostId, selectMediaByIds } from "selectors";
import { theme } from "theme";
import { TPost } from "types";

type Props =
  | {
      index: number;
      post?: undefined;
      placeholder: { top?: boolean; featured?: boolean };
    }
  | {
      index: number;
      post: TPost;
      placeholder?: undefined;
    };

export const PublicacionListItem = ({ index, post, placeholder }: Props) => {
  const [thumbnailIndex, setThumbnailIndex] = useState(0);
  const postMediaIds = useSelector((state) =>
    post ? selectMediaIdsByPostId(state, post.id) : null
  );
  const images = useSelector((state) =>
    postMediaIds
      ? filter(
          selectMediaByIds(state, postMediaIds),
          (mediaItem) => mediaItem.type === "image"
        )
      : null
  );
  const imagesCount = size(images);

  useEffect(() => {
    setThumbnailIndex(random(0, imagesCount - 1));
  }, [imagesCount]);

  const thumbnail = images && images[thumbnailIndex];

  const tags = usePostTags({ postId: post?.id });

  const isXsScreen = useMediaQuery(theme.breakpoints.only("xs"));
  const isSmScreen = useMediaQuery(theme.breakpoints.only("sm"));
  const isMdUpScreen = useMediaQuery(theme.breakpoints.up("md"));

  const getTruncateOptions = () => {
    const options: { length?: number; separator: string } = {
      separator: " ",
    };
    if (isXsScreen) options.length = 60;
    if (isSmScreen) options.length = 100;
    if (isMdUpScreen) options.length = 140;
    return options;
  };

  const Tags = () => {
    return (
      <TagsContainer>
        {(placeholder?.top || tags.top) && <Top />}
        {tags.verified && (
          <Tag src={verifiedImageSrc} alt="verificada" width="30px" />
        )}
        {tags.noAdvancePayment && (
          <IconContainer>
            <MoneyOffIcon />
          </IconContainer>
        )}
        {tags.hasVideo && (
          <Tag src={cameraImageSrc} alt="videos" width="56px" />
        )}
        {!placeholder && <Favourite post={post} />}
      </TagsContainer>
    );
  };

  return (
    <Container index={index} featured={placeholder?.featured || tags.featured}>
      <Hidden smUp>
        <Tags />
      </Hidden>

      <Content>
        {placeholder || !thumbnail ? (
          <PlaceholderImage />
        ) : (
          <SImage
            media={thumbnail}
            size="thumbnail"
            alt="escort post thumbnail"
          />
        )}
        <TextContainer>
          <Hidden smDown>
            <Tags />
          </Hidden>
          <AnimatedTitle isPlaceholder={Boolean(placeholder)}>
            {post?.title}
          </AnimatedTitle>
          <Description isPlaceholder={Boolean(placeholder)}>
            {truncate(post?.text, getTruncateOptions())}
          </Description>
        </TextContainer>
      </Content>
      <Location post={post} />
    </Container>
  );
};

const Container = styled.div<{ index: number; featured?: boolean }>`
  width: 100%;
  background: ${({ index }) =>
    index % 2 === 0 ? "rgba(255, 46, 161, 0.1)" : "transparent"};
  padding: 10px;
  color: white;

  ${({ featured }) =>
    !featured
      ? ""
      : `
  background: rgba(255,255,0, 0.06);
  border: 2px solid ${theme.palette.highlight.main};
  border-radius: 10px;
  box-shadow: 0 0 1.5em ${theme.palette.highlight.main};
    `}

  &:hover {
    background: ${theme.palette.background.light};
  }
`;
const Content = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
`;
const TextContainer = styled.div`
  position: absolute;
  left: 140px;
  right: 0;
`;
const TagsContainer = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  overflow: hidden;
  margin-bottom: 10px;

  > * {
    margin-right: 20px;
  }
`;
const Tag = styled.img<{ width: string }>`
  height: 30px;
  width: ${({ width }) => width};
`;
const gradientAnimation = keyframes`
  0%{ background-position: 50% 50% }
  50% { background-position: 100% 50% }
  100% { background-position: 50% 50% }
`;
const Title = styled.h2<{ isPlaceholder?: boolean }>`
  font-size: 1rem;
  text-transform: uppercase;
  margin: 10px 0;
  color: ${theme.palette.primary.main};

  ${({ isPlaceholder }) => {
    if (isPlaceholder)
      return `
      width: 40%;
      height: 20px;
      background: linear-gradient(-45deg, #ee7752, ${theme.palette.primary.main}, ${theme.palette.blue.main}, #23d5ab);
      background-size: 200% 200%;
    `;
  }}

  ${theme.breakpoints.only("xs")} {
    margin-top: 0;
  }
`;
const AnimatedTitle = styled(Title)`
  animation: ${gradientAnimation} 1.5s ease infinite;
`;
const Description = styled.p<{ isPlaceholder?: boolean }>`
  margin-top: 5px;
  text-overflow: ellipsis;
  line-height: 1.5em;

  ${({ isPlaceholder }) => {
    if (isPlaceholder)
      return `
      background: white;
      opacity: 0.6;
      width: 60%;
      height: 20px;
    `;
  }}
`;
const IMAGE_WIDTH = "120px";
const IMAGE_HEIGHT = "180px";
const SImage = styled(Image)`
  width: ${IMAGE_WIDTH};
  min-width: ${IMAGE_WIDTH};
  max-width: ${IMAGE_WIDTH};
  height: ${IMAGE_HEIGHT};

  img {
    object-fit: cover;
  }
`;
const PlaceholderImage = styled.div`
  min-width: ${IMAGE_WIDTH};
  max-width: ${IMAGE_WIDTH};
  height: ${IMAGE_HEIGHT};
  background: ${theme.palette.background.light};
`;
const IconContainer = styled.div`
  display: flex;

  svg {
    color: white;
    height: 32px;
    width: auto;
  }
`;
