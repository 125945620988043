// Material Components
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MuiSelect from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
// lodash
import map from "lodash/map";
import forEach from "lodash/forEach";

import { useEffect, useState, ReactNode } from "react";
import { useIsMobile, Form } from "common";

type Option = { value: any; label: string };
type OptionGroup = { label: string; options: Array<Option> };

// We do this, because Select does not support a fragment
// as a child
const buildGroupOptions = (groups?: Array<OptionGroup>) => {
  if (!groups) return null;

  const elementArray: Array<ReactNode> = [];

  forEach(groups, (group) => {
    elementArray.push(
      <ListSubheader key={group.label}>{group.label}</ListSubheader>
    );

    forEach(group.options, (option) => {
      elementArray.push(
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  });

  return elementArray;
};

const ADD_NEW_VALUE = "add-new-value";

export const Select = ({
  formKey,
  form,
  options,
  enableAddNew,
}: {
  formKey: string;
  form: Form;
  options: {
    simple?: Array<Option>;
    groups?: Array<OptionGroup>;
  };
  enableAddNew?: { text: string; onAdd: () => void };
}) => {
  const { formValues, onChange } = form;
  const { required, error, label, value } = formValues[formKey];
  const isMobile = useIsMobile();
  const [size, setSize] = useState<"small" | "medium">("small");
  const groupOptions = buildGroupOptions(options.groups);

  useEffect(() => {
    setSize(isMobile ? "small" : "medium");
  }, [isMobile]);

  return (
    <FormControl
      size={size}
      variant="filled"
      fullWidth
      required={required}
      error={Boolean(error)}
    >
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        }}
        value={value ?? ""}
        onChange={({ target: { value } }) => {
          if (value === ADD_NEW_VALUE) {
            enableAddNew?.onAdd();
            return;
          }

          onChange(formKey)(value);
          // Manually blur select or you need to click twice
          // outside of the Select for the blur to happen
          (document.activeElement as HTMLSelectElement)?.blur();
        }}
      >
        {map(options?.simple, ({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
        {enableAddNew && (
          <MenuItem value={ADD_NEW_VALUE}>
            <strong>{enableAddNew.text}</strong>
          </MenuItem>
        )}
        {groupOptions}
      </MuiSelect>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};
