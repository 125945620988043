//lodash
import capitalize from "lodash/capitalize";

import { useSelector, useDispatch, Modal } from "common";
import { TMedia } from "types";
import { deleteMediaRequest } from "requests";
import { openModal, openSnackbar, closeModal } from "actions";
import { selectIsFetching } from "selectors";

export const ConfirmModal = ({ media }: { media: TMedia }) => {
  const dispatch = useDispatch();

  const isFetching = useSelector((state) =>
    selectIsFetching(state, { request: deleteMediaRequest, context: media.id })
  );

  const mediaType = media.type === "image" ? "foto" : "video";

  const deleteMedia = () => {
    dispatch(
      deleteMediaRequest(
        { mediaId: media.id },
        {
          context: media.id,
          onResponse: {
            successCallback: () => {
              dispatch(
                openSnackbar({
                  variant: "success",
                  message: `${capitalize(mediaType)} borrado`,
                })
              );
              dispatch(closeModal());
            },
            errorCallback: (error) => {
              dispatch(closeModal());
              if (error.code === "is-used") {
                dispatch(
                  openModal({
                    node: (
                      <Modal
                        title="Error"
                        variant="warning"
                        cancelButton={{ text: "Continuar" }}
                      >
                        <p>
                          Foto/video no se puede borrar porque está en uso por
                          un anuncio.
                        </p>
                        <p>
                          Remueve la foto de todos tus anuncios e intenta de
                          nuevo.
                        </p>
                      </Modal>
                    ),
                  })
                );
              } else {
                dispatch(
                  openSnackbar({
                    variant: "error",
                    message: "Error al borrar foto/video",
                  })
                );
              }
            },
          },
        }
      )
    );
  };

  return (
    <Modal
      title={`¿Borrar ${mediaType}?`}
      loading={isFetching}
      submitButton={{ text: "Borrar", onClick: deleteMedia }}
    />
  );
};
