import { FormInput } from "common";

export const formKeys = {
  phoneNumber: "phoneNumber",
  code: "code",
};

export const useFormInputs = (): Array<FormInput> => {
  const phoneNumber = {
    key: formKeys.phoneNumber,
    value: "",
    label: "Teléfono móvil",
    placeholder: "10 dígitos",
    type: "tel",
    required: true,
    noWhiteSpace: true,
  };

  const code = {
    key: formKeys.code,
    value: "",
    label: "Código",
    required: true,
    noWhiteSpace: true,
  };

  return [phoneNumber, code];
};
